import {Component, ViewChild, OnInit, ElementRef} from '@angular/core';
import * as moment from 'moment';
import Swal from "sweetalert2";
import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller,
    EffectFade
} from 'swiper';
import {FormGroup, FormBuilder, Validators, NgForm} from '@angular/forms';
import {OrderService} from 'src/app/_services/order.service';

SwiperCore.use([
    EffectFade,
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller
]);

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
    public breadcrumb: any;
    p: any;
    orders: any = [];
    details: any = [];
    selectedCity: string;
    isDetail: boolean = false;
    @ViewChild('ngSelectElement') ngSelectElement: ElementRef;
    selectedItem: any = null;

    orderArray: any = []
    isUpdate: boolean = false;
    selectedUpdateItem: any;
    singleSelectArray = [
        {item_id: 0, item_text: "Tất cả"},
        {item_id: 1, item_text: "Chưa thanh toán, chưa liên hệ"},
        {item_id: 2, item_text: "Đã liên hệ"},
        {item_id: 3, item_text: "Đã thanh toán"},
        {item_id: 4, item_text: "Đang xử lý"},
        {item_id: 5, item_text: "Giao đơn vị vận chuyển"},
        {item_id: 6, item_text: "Hoàn thành"}
    ];
    selectedItem2: any = null;
    // Thêm các biến để lưu trữ các giá trị cho ng-select
    statusOptions = [
        {value: 1, label: 'Option 1'},
        {value: 2, label: 'Option 2'},
        {value: 3, label: 'Option 3'}
    ];
    selectedStatus: number;

    selectedCategory: any = null;

    categories: any[] = [
        {item_id: 1, item_text: "Chưa thanh toán, chưa liên hệ"},
        {item_id: 2, item_text: "Đã liên hệ"},
        {item_id: 3, item_text: "Đã thanh toán"},
        {item_id: 4, item_text: "Đang xử lý"},
        {item_id: 5, item_text: "Giao đơn vị vận chuyển"},
        {item_id: 6, item_text: "Hoàn thành"}
    ];

    checkStatus: number
    orderStatus: number
    idOrder: any

    constructor(private orderService: OrderService) {
    }

    ngOnInit() {
        this.breadcrumb = {
            'mainlabel': 'Quản lý đặt hàng',
            'links': [
                {'name': 'Home', 'isLink': true, 'link': '/changelog'},
                {'name': 'Quản lý đặt hàng', 'isLink': false, 'link': '#'},
            ]
        };

        // Khởi tạo selectedItem với giá trị mặc định
        this.selectedItem = this.singleSelectArray[0];

        this.loadOrder('');
    }

    onChange() {
        console.log(this.selectedItem);
        let status = this.selectedItem.item_id;
        console.log(status);
        this.loadOrder(status);
    }

    loadOrder(status: string) {
        this.orderService.getOrder(status).subscribe(response => {
            console.log(response);
            if (response.success) {
                this.orders = response.data;
            }
        });
    }

    formatDate(date: string): string {
        return moment(date).format('DD/MM/YYYY');
    }

    formatPaymentMethod(data: string): string {
        if (data == "bank") {
            return "Chuyển khoản ngân hàng";
        }
        return data;
    }

    formatStatus(data: string): string {
        const statusMap: { [key: string]: string } = {
            "1": "Chưa thanh toán, chưa liên hệ",
            "2": "Đã liên hệ",
            "3": "Đã thanh toán",
            "4": "Đang xử lý",
            "5": "Giao đơn vị vận chuyển",
            "6": "Hoàn thành"
        };
        return statusMap[data] || data;
    }

    transform(value: number): string {
        if (isNaN(value)) return '';
        const roundedValue = Math.round(value);
        return roundedValue.toLocaleString('vi-VN', {style: 'currency', currency: 'VND'});
    }

    filterStatus(event: any) {
        let value = event.target.value;
        console.log(value);
    }

    convertToDdMmYyyy(timestamp: number): string {
        const dateObject = new Date(timestamp);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
    }

    detail(item: any) {
        console.log(item);
        this.orderArray = item;
        this.orderArray.orderDate = this.convertToDdMmYyyy(this.orderArray.orderDate);
        this.isDetail = true;
    }

    resetDetail() {
        this.details = [];
    }

    resetUpdateStatus() {
        this.isUpdate = false;
        this.selectedUpdateItem = null;
    }

    updateStatus(data: any) {
        this.selectedUpdateItem = data;
        this.checkStatus = this.selectedUpdateItem.orderStatus
        this.orderStatus = this.selectedUpdateItem.orderStatus
        this.idOrder = this.selectedUpdateItem.Id
        console.log('this.selectedUpdateItem ', this.selectedUpdateItem);
        this.selectedUpdateItem.orderDate = this.formatDate(this.selectedUpdateItem.orderDate);
        this.isUpdate = true;
    }

    changeStatus(event: any) {
        let id = event.target.getAttribute('id')
        this.orderStatus = id
    }

    updateData() {

        console.log('data updated ', this.orderStatus, this.idOrder)

        this.orderService.updateData(this.orderStatus, this.idOrder).subscribe(item => {
            Swal.fire({
                title: 'Cập nhật thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

                this.orders = [];
                this.loadOrder('');
                this.isUpdate = false;

            });
        });
    }

    protected readonly Number = Number;
}
