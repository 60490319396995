import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { NewsService } from "../../_services/news.service";
import Swal from "sweetalert2";
import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller,
    EffectFade
} from 'swiper';
import { environment } from "../../../environments/environment";
import {  NgForm } from '@angular/forms';

SwiperCore.use([EffectFade,
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller]);

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

    constructor(private newService: NewsService) {
        this.baseUrl = 'https://englishapi.tranchaumood.com';
    }
    @ViewChild('f', { read: true }) userProfileForm: NgForm;


    testModel: boolean = false;
    testTxt: string = '';
    testTxtDisplay: string = '';
    @ViewChild('labelImport1', { static: true }) labelImport1: ElementRef;
    @ViewChild('labelImport2', { static: true }) labelImport2: ElementRef;
    @ViewChild('labelImport3', { static: true }) labelImport3: ElementRef;
    @ViewChild('labelImport4', { static: true }) labelImport4: ElementRef;
    @ViewChild('labelImport5', { static: true }) labelImport5: ElementRef;
    @ViewChild('labelImport6', { static: true }) labelImport6: ElementRef;


    labelImport: ElementRef;
    fileToUpload: File = null;
    editorConfig: any;

    fileToUpload1: File = null;
    fileToUpload2: File = null;
    fileToUpload3: File = null;
    fileToUpload4: File = null;
    fileToUpload5: File = null;
    fileToUpload6: File = null;


    txtContent1 : string = "";
    txtContent2 : string = "";
    txtContent3 : string = "";
    txtContent4 : string = "";
    isSubmitted: boolean = false;
    

    isView : boolean = false;
    isUpdate : boolean = false;
    isUpdateImage : boolean = false;

    titleView : any;
    content1 : any;
    content2 : any;
    content3 : any;
    content4 : any;
    imgContent1:any = [];
    imgContent2:any = [];
    imgContent3:any = [];
    imgContent4:any = [];

    baseUrl: string ;
    selectedItem : any;
    selectedItemPosition: number;
    selectedStatus: any;
    selectedImageStatus : any;

    isAddImage : boolean = false;
    options: Array<{ name: string, value: number }> = [
        { name: 'Nội dung 1', value:11},
        { name: 'Nội dung 2', value: 12},
        { name: 'Nội dung 3', value: 13},
        { name: 'Nội dung 4', value: 14 }

      ];
    selectedOption : any;
    selectedImage: string | ArrayBuffer | null = null;






    public breadcrumb: any;
    isCreate: boolean;
    isShowModalTemplate: boolean;
    txtTitleAdd: string = '';
    txtUrlAdd: string = '';
    txtDesTemplate1: string = '';
    nameImage1Template1: any
    nameImage2Template1: any
    nameImage3Template1: any
    nameImage4Template1: any
    txtDes1Template2: string = '';
    txtDes2Template2: string = '';
    txtDes3Template2: string = '';
    txtDes4Template2: string = '';

    txtDesTemplate3: string = '';

    valTEmplate: number = 1;

    selectedFile1: any;
    selectedFile2: any;
    selectedFile3: any;
    selectedFile4: any;

    selectedFileThumbnail: any

    listItem = [];
    p: any;

    listTmp: any = [];

    isShowViewImage: boolean = false
    desImageNews: any = {}

    sliderView1: any = []
    sliderView2: any = []
    sliderView3: any = []
    sliderView4: any = []
    listItemDetals = [];
    selectedOptionDetails: any;

    isShowEditNews: boolean;

    isDataNews: any = {}

    titleNews: string;
    isShowEditMediaDetails: boolean;
    isShowAddImage: boolean = false;

    toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        ['link', 'image', 'video', 'formula'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];

    filesToUpload: { [key: number]: File[] } = {};

    updateAndCopyMedia = (mediaArray, targetArray) => {
        mediaArray.forEach(mediaItem => {
            // Tạo một bản sao sâu của đối tượng
            let mediaCopy = JSON.parse(JSON.stringify(mediaItem));
    
            // Thực hiện các thay đổi trên bản sao
            mediaCopy["url"] = `${environment.URL}/${mediaCopy["url"].replace("Publics/", "")}`;
    
            // Đẩy bản sao vào danh sách đích
            targetArray.push(mediaCopy);
        });
    };

    processMediaContent = (mediaArray, kind, listItemDetals) => {
        mediaArray.forEach(mediaItem => {
            // Tạo một bản sao sâu của đối tượng
            let mediaCopy = JSON.parse(JSON.stringify(mediaItem));
            
            // Thực hiện các thay đổi trên bản sao
            mediaCopy["kind"] = kind;
            let tmp = mediaCopy["url"].replace("Publics/", "");
            mediaCopy["url"] = `${environment.URL}/${tmp}`;
    
            // Đẩy bản sao vào danh sách đích
            listItemDetals.push(mediaCopy);
        });
    };

    submitEditImage(item,fileImage)
    {
        console.log(item);
        if(Number(item.type) == 0)
        {
            let file = fileImage.files[0];
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                this.newService.updateThum(formData, item.id).subscribe(item => {
                    console.log(item);
    
                    Swal.fire({
                        title: 'Cập nhật thành công',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
    
                        this.listItemDetals = [];
                        this.load();
                        this.isUpdateImage = false;
    
                    });
                });
            }

        }
        else
        {
            let file = fileImage.files[0];
            if (file) {
                const formData = new FormData();
                formData.append('position', item.position);
                formData.append('status', item.status);
                formData.append('file', file);
    
                this.newService.updateImage(formData, item.id).subscribe(item => {
                    console.log(item);
    
                    Swal.fire({
                        title: 'Cập nhật thành công',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
    
                        this.listItemDetals = [];
                        this.load();
                        this.isUpdateImage = false;
    
                    });
                });
            }

        }


    }


    handleChange(event) {

        if (event.target.checked) {
            this.selectedStatus = event.target.value;

        }

    }

    previewNews(item) {
        console.log(item);
        this.selectedItem = item;
        this.isView = true;
        this.titleView = item.title;
        this.content1 = item.content;
        this.content2 = item.content2;
        this.content3 = item.content3;
        this.content4 = item.content4;

        // Cập nhật và sao chép các phần tử trong img_content1
        this.updateAndCopyMedia(item.media.img_content1, this.imgContent1);

        // Cập nhật và sao chép các phần tử trong img_content2
        this.updateAndCopyMedia(item.media.img_content2, this.imgContent2);

        // Cập nhật và sao chép các phần tử trong img_content3
        this.updateAndCopyMedia(item.media.img_content3, this.imgContent3);

        // Cập nhật và sao chép các phần tử trong img_content4
        this.updateAndCopyMedia(item.media.img_content4, this.imgContent4);

    
    }


    resetView()
    {
        this.titleView = "";
        this.content1 = "";
        this.content2 = "";
        this.content3 = "";
        this.content4 = "";
        this.imgContent1 = [];
        this.imgContent2 = [];
        this.imgContent3 = [];
        this.imgContent4 = [];

    }

    resetUpdate()
    {
        this.titleView = "";
        this.content1 = "";
        this.content2 = "";
        this.content3 = "";
        this.content4 = "";

    }


    submitCreateNews() {
        let formData = new FormData();
        formData.append('title', this.txtTitleAdd);
        formData.append('content', this.txtContent1);
        formData.append('content2', this.txtContent2);
        formData.append('content3', this.txtContent3);
        formData.append('content4', this.txtContent4);
        
        // Append other content and files
        Object.keys(this.filesToUpload).forEach(type => {
            this.filesToUpload[type].forEach(file => {
            formData.append(`${type}`, file);
            });
        });




        this.newService.insertDataTemplate1V2(formData).subscribe(item => {
            console.log('item data template 1 ', item)
            this.refresh();
            if (item.success == true) {
                Swal.fire({
                    title: 'Thêm thành công',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    // this.txtDescriptionAdd = '';
                    // this.listItem = [];
                    // this.load();
                    this.txtTitleAdd = ''
                    this.txtUrlAdd = ''
                    this.isShowModalTemplate = false;
                    this.isCreate = false
                });
            }
        })
    }

    canSubmit(): boolean {
        return this.txtTitleAdd && (this.fileToUpload5 != null);
    }

    canEdit(): boolean {
        return this.txtTitleAdd != null && this.txtTitleAdd != "";
    }


    resetForm(): void {
        this.txtTitleAdd = '';
        this.txtContent1 = '';
        this.txtContent2 = '';
        this.txtContent3 = '';
        this.txtContent4 = '';
        this.isSubmitted = false;
        this.filesToUpload = {};
        this.fileToUpload5 = null;
        this.labelImport1.nativeElement.innerText = 'Chọn file';
        this.labelImport2.nativeElement.innerText = 'Chọn file';
        this.labelImport3.nativeElement.innerText = 'Chọn file';
        this.labelImport4.nativeElement.innerText = 'Chọn file';
        this.labelImport5.nativeElement.innerText = 'Chọn file';
    }

    resetAddImage() : void {
        this.selectedOption = this.options[0].value;
        this.selectedImage = null;
        this.labelImport6.nativeElement.innerText = 'Chọn file';
        this.fileToUpload6 = null;

        
    }




    breakpoints = {
        320: { slidesPerView: 1, spaceBetween: 0 },
        640: { slidesPerView: 1, spaceBetween: 0 },
        851: { slidesPerView: 1, spaceBetween: 0 },
    };


    ngOnInit() {
        this.selectedOption = this.options[0].value;
        this.breadcrumb = {
            'mainlabel': 'Quản lý tin tức',
            'links': [
                {
                    'name': 'Home',
                    'isLink': true,
                    'link': '/changelog'
                },
                {
                    'name': 'Quản lý tin tức',
                    'isLink': false,
                    'link': '#'
                },
            ]
        };

        this.editorConfig = {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                //   ['blockquote', 'code-block'],
                //   [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                //   [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                //   [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                //   [{ 'direction': 'rtl' }],                         // text direction
                //   [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                //   [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                //   [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                //   [{ 'align': [] }],
                //   ['clean'],                                        // remove formatting button
                //   ['link', 'image', 'video']                        // link and image, video
            ]
        };


        this.load();

    }

    load() {
        let accesstoken = localStorage.getItem('accesstoken');
        this.listItem = [];
        this.listTmp = [];
        this.newService.getNews().subscribe(item => {
            console.log(item);
            item.data.forEach(item => {
                // item.created_at = this.convertToDdMmYyyy(item.created_at);
                // item.updated_at = this.convertToDdMmYyyy(item.updated_at);
                this.listItem.push(item);
                this.listTmp = this.listItem;

            });
        });
    }



    onFileChange(files: FileList, type: number) {



        const fileArray = Array.from(files);

        this.filesToUpload[`file_${type}`] = fileArray;

        if (type == 1) {
            this.labelImport1.nativeElement.innerText = Array.from(files)
                .map(f => f.name)
                .join(', ');
            // this.fileToUpload1 = files.item(0);
        }
        if (type == 2) {
            this.labelImport2.nativeElement.innerText = Array.from(files)
                .map(f => f.name)
                .join(', ');
            // this.fileToUpload2 = files.item(0);
        }
        if (type == 3) {
            this.labelImport3.nativeElement.innerText = Array.from(files)
                .map(f => f.name)
                .join(', ');
            // this.fileToUpload3 = files.item(0);
        }
        if (type == 4) {
            this.labelImport4.nativeElement.innerText = Array.from(files)
                .map(f => f.name)
                .join(', ');
            // this.fileToUpload4 = files.item(0);

        }
        if (type == 5) {
            this.labelImport5.nativeElement.innerText = Array.from(files)
                .map(f => f.name)
                .join(', ');
            if (files.length > 0) {

                this.fileToUpload5 = files.item(0);

            }
            else
            {
                this.fileToUpload5 = null;
            }

        }
        if (type == 6) {

            this.labelImport6.nativeElement.innerText = Array.from(files)
                .map(f => f.name)
                .join(', ');
            if (files.length > 0) {

                this.fileToUpload6 = files.item(0);
                if (files && files.length > 0) {
                    const file = files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      this.selectedImage = e.target.result;
                    };
                    reader.readAsDataURL(file);
                  }


            }
            else
            {
                this.selectedImage = null;

                this.fileToUpload6 = null;
            }
        }
        console.log(this.filesToUpload);


    }


    editImage(item) {

        console.log(item);
        this.selectedItem = item;
        this.isUpdateImage = true;

        this.listItemDetals = [];

        this.listItemDetals.push({
            kind : "Ảnh Thum",
            type : 0,
            url : `${environment.URL}/${item.thum_url.replace("Publics/","")}`,
            position:0,
            status : 1,
            id : item.id
        })
        if (item.media["img_content1"] && item.media["img_content1"].length > 0) {
            this.processMediaContent(item.media["img_content1"], "Ảnh nội dung 1", this.listItemDetals);
        }
        
        if (item.media["img_content2"] && item.media["img_content2"].length > 0) {
            this.processMediaContent(item.media["img_content2"], "Ảnh nội dung 2", this.listItemDetals);
        }
        
        if (item.media["img_content3"] && item.media["img_content3"].length > 0) {
            this.processMediaContent(item.media["img_content3"], "Ảnh nội dung 3", this.listItemDetals);
        }
        
        if (item.media["img_content4"] && item.media["img_content4"].length > 0) {
            this.processMediaContent(item.media["img_content4"], "Ảnh nội dung 4", this.listItemDetals);
        }
        

        
        console.log(this.listItemDetals);
        
    }

    handleChangeStatusImage(event: any, item:any) {
        // Handle change details logic here
        // if (event.target.checked) {
        //     this.selectedImageStatus = event.target.value;

        // }
        item.status = event.target.value;
    }

    uploadImageUpdateDetails(event: any, item: any) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                item.url = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    uploadAddImage(event: any) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                this.selectedImage = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    addImage(item) {
        // alert("chon");
        console.log(item);
        this.isAddImage = true;

    }

    submitAddImage(item, fileImage)
    {
        console.log(fileImage);
        let file = fileImage.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', this.selectedOption);
        formData.append('template_id', item.id);
        this.newService.insertImage(formData,item.id).subscribe(item => {
            // console.log(item);
            // this.listItem = [];
            // this.load();
            if (item.success == true) {
                Swal.fire({
                    title: 'Thêm thành công',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    // this.txtDescriptionAdd = '';
                    this.listItem = [];
                    this.load();
                    this.isAddImage = false;
                    this.isUpdateImage = false;
                    this.resetAddImage();
                });
            }
    
        });

        // if(file && file.length > 0)
        // {
    


        // }
    }

    canSubmitAddImage() : boolean
    {
        return this.fileToUpload6 != null;

    }

    onSelectChange(event: any): void {
        console.log('Selected option:', this.selectedOption);
        // Thực hiện các hành động khác tại đây
      }



    removeImage(item) {
        console.log(item);
        Swal.fire({
            title: 'Bạn có muốn xoá không ?',
            // text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                let id = item.id;
                console.log(id);
            
                this.newService.deleteImage(id).subscribe(item => {
                    console.log(item);
                    this.listItem = [];
                    this.load();
            
                });
                this.isUpdateImage = false;
            
                Swal.fire({
                    title: 'Xoá ảnh',
                    text: 'Xoá thành công',
                    icon: 'success'
                });
            }
        });
    }

    handleChangeDetails(event) {
        // console.log(event.target)
        if (event.target.checked) {
            this.selectedOptionDetails = event.target.value;
            console.log(this.selectedOptionDetails);
        }

    }



    editNews(item) {
        this.isUpdate = true;
        this.selectedItem = item;
        this.txtTitleAdd = item.title;
        this.txtContent1 = item.content;
        this.txtContent2 = item.content2;
        this.txtContent3 = item.content3;
        this.txtContent4 = item.content4;
        this.selectedStatus = Number(item.status);
        this.selectedItemPosition = Number(item.position);

    }

    submitEditNews()
    {
        let body = {
            'title': this.txtTitleAdd,
            'content': this.txtContent1,
            'content2': this.txtContent2,
            'content3': this.txtContent3,
            'content4': this.txtContent4,
            'position' : this.selectedItemPosition,
            'status' : this.selectedStatus
        }

        this.newService.updateContentNews(body, this.selectedItem.id).subscribe(item => {

            Swal.fire({
                title: 'Cập nhật thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

                this.listItem = [];
                this.load();
                this.isUpdate = false;

            });
        });

    }




    updateData(id, title, content, position) {



        let data = { id, title, content, position };

        console.log(data)


        let body = {
            'title': data.title,
            'content': data.content,
            'position': data.position
        }

        this.newService.updateData(body, id).subscribe(item => {

            Swal.fire({
                title: 'Sửa thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

                this.listItem = [];
                this.load();
                this.isShowEditNews = false;

            });
        });

        // this.isShowEditMedia = false
    }

    updateDataDetails(itemUpdate, id, position, status) {


        // let tmpStatus = this.selectedOptionDetails;
        let tmpStatus: any = Number(this.selectedOptionDetails)


        let data = { id, position, tmpStatus };

        let body = {
            'position': data.position,
            'status': data.tmpStatus
        }
        console.log(itemUpdate);

        this.newService.updateDataDetails(data, body).subscribe(item => {

            console.log('item ', item);
            console.log('this.listItemDetals ', this.listItemDetals);
            Swal.fire({
                title: 'Sửa thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

                for (let i = 0; i < this.listItemDetals.length; i++) {
                    if (this.listItemDetals[i].id === itemUpdate.id) {
                        this.listItemDetals.splice(i, 1); // Xóa phần tử tại vị trí i
                        break; // Sau khi xóa phần tử, thoát khỏi vòng lặp
                    }
                }

                this.listItemDetals.push(itemUpdate);
                this.refresh();
                this.isShowViewImage = false;

            });
        });
    }

    refresh() {
        this.listItem = [];
        this.listItemDetals = [];
        this.load();
    }

    removeNews(item) {
        let accesstoken = localStorage.getItem('accesstoken');


        Swal.fire({
            title: 'Bạn có muốn xoá không ?',
            // text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                let id = item.id;
                console.log(id);

                this.newService.deleteData(id).subscribe(item => {
                    console.log(item);
                    this.refresh();

                });
                // this.listItem = [];
                // this.load();


                Swal.fire({
                    title: 'Đã xoá!',
                    // text: 'Your file has been deleted.',
                    icon: 'success'
                });
            }
        });
    }

    closeDialog() {
        this.sliderView1 = []
        this.sliderView2 = []
        this.sliderView3 = []
        this.sliderView4 = []
    }

    showModalAdd() {
        this.isCreate = true;
    }



    showModalTemplate() {
        console.log(this.valTEmplate)
        this.isShowModalTemplate = true;
    }

    back(event: any) {
        this.isShowModalTemplate = false
    }

    uploadThumbnail(event: any) {
        if (event.target.files.length > 1) {
            alert('Bạn chỉ có thể chọn tối đa 1 file.');
            event.target.value = ''
        } else {
            this.selectedFileThumbnail = event.target.files;
        }
    }




}
