import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {MediaService} from '../../_services/media.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {

    constructor(private mediaService: MediaService) {

    }

    public breadcrumb: any;

    isShowModalAdd: boolean;
    isShowViewMedia: boolean;
    isShowEditMedia: boolean;

    isDataMedia: any = {};

    txtTitleAdd: string = '';
    txtDescriptionAdd: string = '';
    txtLinkAdd: string = '';
    nameImageAdd: any;

    nameImageUpdate: any;
    statusImageUpdate: any;

    urlImageEdit: string = '';

    selectedOption: any;

    listItem: any = [];

    urlThumView :string;

    ngOnInit() {
        this.breadcrumb = {
            'mainlabel': 'Media',
            'links': [
                {
                    'name': 'Home',
                    'isLink': true,
                    'link': '/changelog'
                },
                {
                    'name': 'Media',
                    'isLink': false,
                    'link': '#'
                },
            ]
        };

        this.load();
    }

    load() {
                let accesstoken = localStorage.getItem('accesstoken');
        this.mediaService.getMedia(accesstoken, `/medias`).subscribe(item => {
            item.data.forEach(item => {
                item.created_at = this.convertToDdMmYyyy(item.created_at);
                item.updated_at = this.convertToDdMmYyyy(item.updated_at);
                this.listItem.push(item);
            });
        });
    }

    convertToDdMmYyyy(timestamp) {
        const dateObject = new Date(timestamp);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
    }

    //add

    uploadImageAdd(event: Event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageAdd = fileList[0];
        }
    }


    addData(title, description, link, fileInputAdd) {
                let accesstoken = localStorage.getItem('accesstoken');

        if (title && description && link && this.nameImageAdd) {
            let thum = this.nameImageAdd;

            let data = {title, description, link, thum};

            this.mediaService.postData(accesstoken, data, '/medias').subscribe(item => {

                Swal.fire({
                    title: 'Thêm thành công',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    fileInputAdd.value = '';
                    this.txtTitleAdd = '';
                    this.txtLinkAdd = '';
                    this.txtDescriptionAdd = '';
                    this.listItem = [];
                    this.load();
                    this.isShowModalAdd = false;
                });
            });


        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Lỗi !',
                text: 'Vui lòng nhập đủ thông tin mô tả và hình ảnh',
                // footer: '<a href="#">Why do I have this issue?</a>'
            });
        }
    }

    showModalAdd() {
        this.isShowModalAdd = true;
    }

    viewMedia(url) {
        url = url.replace('Publics/', '');
        this.urlImageEdit = environment.URL + '/'+url;
        console.log(this.urlImageEdit);
        this.isShowViewMedia = true;
    }

    //update

    uploadImageUpdate(event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageUpdate = fileList[0];
        }
    }

    handleChange(event) {
        // console.log(event.target)
        if (event.target.checked) {
            this.selectedOption = event.target.value;
            console.log(this.selectedOption);
        }

    }

    updateData(id, title, description, url_media, position, status, fileInput) {
                let accesstoken = localStorage.getItem('accesstoken');

        let tmpStatus = Number(this.selectedOption);
        let tmpImage = this.nameImageUpdate;

        let data = {id, title, description, url_media, tmpStatus, position, tmpImage};

        this.mediaService.updateData(accesstoken, data, '/medias').subscribe(item => {
            Swal.fire({
                title: 'Sửa thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

                this.nameImageUpdate = '';
                fileInput.value = '';
                this.listItem = [];
                this.load();
                this.isShowEditMedia = false;

            });
        });
    }

    editMedia(item) {
        this.isShowEditMedia = true;
        this.isDataMedia = item;
        this.selectedOption = item.status;

    }

    removeMedia(item) {
                let accesstoken = localStorage.getItem('accesstoken');

        Swal.fire({
            title: 'Bạn có muốn xoá không ?',
            // text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                let id = item.id;
                console.log(id);

                this.mediaService.deleteData(accesstoken, id, '/medias').subscribe(item => {
                    console.log(item);
                });
                this.listItem = [];
                this.load();

                Swal.fire({
                    title: 'Đã xoá!',
                    // text: 'Your file has been deleted.',
                    icon: 'success'
                });
            }
        });
    }

}
