<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <section id="drag-area">

                <div class='main-table-containter'>

                    <div class="d-flex justify-content-between mb-1 box-head">
                        <p-button (click)="showModalAdd()" icon="pi pi-plus" label="Thêm"></p-button>
                        <p-dialog header="Thêm đối tác" [(visible)]="isShowModalAdd" [modal]="true"
                                  [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw', height: '80vh' }"
                                  [draggable]="false" [resizable]="false">
                            <div class="mb-2">
                                <label for="" class="form-label">Tên đối tác</label>
                                <input [(ngModel)]="namePartner" type="text" class="form-control" id="">
                            </div>
                            <div class="mb-3">
                                <p>Chọn ảnh thumbnail</p>
                                <input #fileThumbAdd accept="image/*" (change)="uploadImageAddThumb($event)" type="file"
                                       id="formFile">
                            </div>
                            <div class="mb-1">
                                <p>Chọn ảnh 1</p>
                                <input #fileThumbAdd1 accept="image/*" (change)="uploadImageAdd1($event)" type="file"
                                       id="formFile">
                            </div>
                            <div class="mb-3">
                                <label for="" class="form-label">Mô tả 1</label>
                                <p-editor [(ngModel)]="txtDescriptionAdd1" [style]="{ height: '180px' }"></p-editor>
                            </div>
                            <div class="mb-1">
                                <p>Chọn ảnh 2</p>
                                <input #fileThumbAdd2 accept="image/*" (change)="uploadImageAdd2($event)" type="file"
                                       id="formFile">
                            </div>
                            <div class="mb-3">
                                <label for="" class="form-label">Mô tả 2</label>
                                <p-editor [(ngModel)]="txtDescriptionAdd2" [style]="{ height: '180px' }"></p-editor>
                            </div>
                            <div class="d-flex justify-content-end">
                                <p-button
                                        (click)="addData(namePartner, fileThumbAdd, fileThumbAdd1, txtDescriptionAdd1, fileThumbAdd2, txtDescriptionAdd2)"
                                        label="Lưu" icon="pi pi-check"></p-button>
                            </div>
                        </p-dialog>

                        <div class="d-flex box-filter" style="gap: 10px">
                            <div class="radio-input">
                                <label>
                                    <input (change)="filterStatus($event)" value="all" name="value-radio" id="value-1" type="radio" checked>
                                    <span>Tất cả</span>
                                </label>
                                <label>
                                    <input (change)="filterStatus($event)" value="1" name="value-radio" id="value-2" type="radio">
                                    <span>Hoạt động</span>
                                </label>
                                <label>
                                    <input (change)="filterStatus($event)" value="0" name="value-radio" id="value-3" type="radio">
                                    <span>Không hoạt động</span>
                                </label>
                                <span class="selection"></span>
                            </div>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"></i>
                                <input type="text" pInputText placeholder="Nhập tên đối tác" (keydown)="search($event)"
                                       [(ngModel)]="valueSearch"/>
                            </span>
                        </div>
                    </div>

                    <div class="box-table table-responsive">


                        <table class="table">
                            <thead class="table-dark">
                            <tr>
                                <td>#</td>
                                <td>Tên đối tác</td>
                                <td>Mô tả 1</td>
                                <td>Mô tả 2</td>
                                <td>Vị trí</td>
                                <td>Trạng thái</td>
                                <td>Ngày cập nhật</td>
                                <td>Action</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of listItem | paginate: { itemsPerPage: 5, currentPage: p }">
                                <td [innerHTML]="item.id"></td>
                                <td [innerHTML]="item.name"></td>
                                <td class="txt-line" [innerHTML]="item.description1"></td>
                                <td class="txt-line" [innerHTML]="item.description2"></td>
                                <td [innerHTML]="item.position"></td>
                                <td>
                                    <span *ngIf="item.status == 1; else elseBlock">Hoạt động</span>
                                    <ng-template #elseBlock>
                                        <span>Không hoạt động</span>
                                    </ng-template>
                                </td>
                                <td>
                                    <p>Ngày tạo: {{ item.created_at }}</p>
                                    <p class="mb-0">Ngày cập nhật: {{ item.updated_at }}</p>
                                </td>
                                <td>

                                    <div class="row justify-content-center" style="gap: 10px">
                                        <div class="box">
                                            <p-button class="btn-view" icon="pi pi-eye"  (click)="viewPartner(item.url_thum, item.url_image1, item.url_image2)" label="Xem"></p-button>
                                            <p-dialog header="Hình ảnh" [(visible)]="isShowViewPartner" [modal]="true"
                                                      [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw', height: '80vh' }"
                                                      [draggable]="false" [resizable]="false">
                                                <p class="text-left">Hình thumbnail</p>
                                                <p class="text-center view-edit-img">
                                                    <img src="http://18.224.109.137:1026/{{this.urlMediaThumbEdit}}"
                                                         alt="">
                                                </p>

                                                <div>
                                                    <p class="text-left">Hình partner 1</p>
                                                    <p class="text-center view-edit-img">
                                                        <img src="http://18.224.109.137:1026/{{this.urlMediaThumbEdit1}}"
                                                             alt="">
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="text-left">Hình partner 2</p>
                                                    <p class="text-center view-edit-img">
                                                        <img src="http://18.224.109.137:1026/{{this.urlMediaThumbEdit2}}"
                                                             alt="">
                                                    </p>
                                                </div>
                                            </p-dialog>
                                        </div>

                                        <div class="box">
                                            <p-button (click)="editPartner(item)"  icon="pi pi-cog" label="Sửa"></p-button>

                                        </div>

                                        <div class="box">
                                            <p-button class="btn-remove" icon="pi pi-trash"  (click)="removePartner(item)"
                                                      label="Xoá"></p-button>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="pagination" (pageChange)="p = $event"></pagination-controls>

                    </div>

                </div>

            </section>
        </div>
    </div>
</div>

<p-dialog header="Sửa đối tác" [(visible)]="isShowEditPartner" [modal]="true"
          [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw', height: '80vh' }"
          [draggable]="false" [resizable]="false">

    <div class="mb-3">
        <label for="" class="form-label">Tên đối tác</label>
        <input [(ngModel)]="isDataPartner.name" type="text" class="form-control" id="" value="{{isDataPartner.name}}">
    </div>

    <div class="mb-3">
        <p>Chọn ảnh thumbnail</p>
        <input #fileInputUpdateThumb accept="image/*" (change)="uploadImageUpdateThumb($event)" type="file" id="">

    </div>

    <div class="mb-1">
        <p>Chọn ảnh 1</p>
        <input #fileInputUpdate1 accept="image/*" (change)="uploadImageUpdate1($event)" type="file" id="">

    </div>

    <div class="mb-3">
        <label for="" class="form-label">Mô tả 1</label>
        <p-editor [(ngModel)]="isDataPartner.description1" [style]="{ height: '180px' }" data-value=""></p-editor>
    </div>

    <div class="mb-1">
        <p>Chọn ảnh 2</p>
        <input #fileInputUpdate2 accept="image/*" (change)="uploadImageUpdate2($event)" type="file" id="">

    </div>

    <div class="mb-3">
        <label for="" class="form-label">Mô tả 2</label>
        <p-editor [(ngModel)]="isDataPartner.description2" [style]="{ height: '180px' }" data-value=""></p-editor>
    </div>

    <div class="mb-3">
        <label for="txtarea3" class="form-label">Vị trí</label>
        <input type="number" class="form-control" id="txtarea3" [(ngModel)]="isDataPartner.position"
               value="{{isDataPartner.position}}">
    </div>

    <div class="mb-3">
        <p class="form-label">Trạng thái</p>

        <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" value="1" (change)="handleChange($event)"
                   [checked]="selectedOption == 1" type="radio" name="inlineRadioOptions" id="inlineRadio1">
            <label class="form-check-label cursor-pointer" for="inlineRadio1">Hoạt động</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" value="0" (change)="handleChange($event)"
                   [checked]="selectedOption == 0" type="radio" name="inlineRadioOptions" id="inlineRadio2">
            <label class="form-check-label cursor-pointer" for="inlineRadio2">Không hoạt động</label>
        </div>


    </div>

    <div class="d-flex justify-content-end">
        <p-button (click)="updateData(isDataPartner.id, isDataPartner.name, fileInputUpdateThumb, fileInputUpdate1, isDataPartner.description1, fileInputUpdate2, isDataPartner.description2, isDataPartner.position, isDataPartner.status)" label="Lưu" icon="pi pi-check"></p-button>
    </div>
</p-dialog>


