import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import Swal from 'sweetalert2';
import { RecipesService } from "../../_services/recipes.service";
import { environment } from '../../../environments/environment';
import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller,
    EffectFade
} from 'swiper';

SwiperCore.use([EffectFade,
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller]);
@Component({
    selector: 'app-recipes',
    templateUrl: './recipes.component.html',
    styleUrls: ['./recipes.component.css']
})
export class RecipesComponent implements OnInit {

    @ViewChild('labelImport', { static: true })
    labelImport: ElementRef;

    constructor(
        private recipesService: RecipesService
    ) {

    }

    fileInputAdd: any = null;
    fileInputAdd1: any = null;
    fileInputAdd2: any = null;

    public breadcrumb: any;
    isShowEditMediaDetails: boolean;

    isShowModalAdd: boolean;
    isShowViewRecipes: boolean;
    isShowEditRecipes: boolean;

    isDataRecipes: any = {};

    txtDescriptionAdd: string = ''
    nameImageThumbAdd: any
    nameImageMainAdd: any
    nameImageMainLeftAdd: any

    nameImageThumbUpdate: any
    nameImageMainUpdate: any
    nameImageMainLeftUpdate: any

    listTmp: any = [];


    nameImageUpdate: string = '';
    statusImageUpdate: any;

    selectedOption: any


    txtUpdateDescription: string = ''

    txtTitleAdd: string = ''

    urlVideo: string = ''
    listItem = []

    dataView: any = {}

    listImageMain: any = []
    listImageMainLeft: any = []
    listItemDetals = [];

    imageLeftName: string;
    selectedItem: any;
    isShowAddImage: boolean = false;
    fileToUpload: File = null;
    isShowVideo: boolean = false;
    videoItem; any;

    ngOnInit() {
        this.breadcrumb = {
            'mainlabel': 'Công thức',
            'links': [
                {
                    'name': 'Home',
                    'isLink': true,
                    'link': '/changelog'
                },
                {
                    'name': 'Công thức',
                    'isLink': false,
                    'link': '#'
                },
            ]
        };

        this.load()
    }

    canSubmitAddImage(): boolean {

        return this.fileToUpload != null;
    }

    resetFormAddImage() : void{
        this.labelImport.nativeElement.innerText = 'Chọn file';
        this.fileToUpload = null;
    }

    canSubmitAdd(): boolean {
        return this.txtTitleAdd != null && this.nameImageThumbAdd != null;
    }

    resetAddRecipeForm() {
        this.txtTitleAdd = '';
        this.urlVideo = '';
        this.txtDescriptionAdd = '';
        if (this.fileInputAdd) {
            this.fileInputAdd.value = null;
        }
        if (this.fileInputAdd1) {
            this.fileInputAdd1.value = null;
        }
        if (this.fileInputAdd2) {
            this.fileInputAdd1.value = null;
        }

    }

    convertToDdMmYyyy(timestamp) {
        const dateObject = new Date(timestamp);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
    }

    load() {
        let accesstoken = localStorage.getItem('accesstoken');

        this.recipesService.getRecipes().subscribe(item => {

            item.data.forEach(item => {
                item.created_at = this.convertToDdMmYyyy(item.created_at);
                item.updated_at = this.convertToDdMmYyyy(item.updated_at);
                this.listItem.push(item);
                this.listTmp = this.listItem;

            });

            // this.videoItem = this.listItem[0].url_video;
        });
    }
    showUrlVideo() {
        this.urlVideo = "";
        this.isShowVideo = true;
    }

    showModalAdd() {
        this.isShowModalAdd = true;
    }

    editImageleft(item) {


        this.imageLeftName = item.title;
        this.isShowEditMediaDetails = true;

        this.selectedItem = item;
        this.listItemDetals = [];



        item.recipes_imgs.forEach(item => {
            let dataSub = {}
            dataSub["url"] = environment.URL + '/' + item.url.replace('Publics/', '');
            dataSub["position"] = item.position;
            dataSub["status"] = Number(item.status);
            dataSub["id"] = item.id;
            dataSub["type"] = Number(item.type);
            this.listItemDetals.push(dataSub);




        });

    }

    uploadImageThumbAdd(event: any) {



        if (event.target.files.length == 0) {
            this.nameImageThumbAdd = null;
        }
        else if (event.target.files.length == 1) {
            this.nameImageThumbAdd = event.target.files;

        }
        else {
            alert('Bạn chỉ có thể chọn tối đa 1 file.');
            this.nameImageThumbAdd = null;

        }


        // if (event.target.files.length > 1) {
        //     alert('Bạn chỉ có thể chọn tối đa 1 file.');
        //     event.target.value = ''
        // } else {
        //     this.nameImageThumbAdd = event.target.files;
        // }
    }

    uploadImageMainAdd(event: any) {
        if (event.target.files.length > 3) {
            alert('Bạn chỉ có thể chọn tối đa 3 file.');
            event.target.value = ''
        } else {
            this.nameImageMainAdd = event.target.files;
        }
    }

    uploadImageMainLeftAdd(event: any) {
        if (event.target.files.length > 3) {
            alert('Bạn chỉ có thể chọn tối đa 3 file.');
            event.target.value = ''
        } else {
            this.nameImageMainLeftAdd = event.target.files;
        }
    }
    removeImageLeft(item) {

        Swal.fire({
            title: 'Bạn có muốn xoá không ?',
            // text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                let id = item.id;


                this.recipesService.deleteImageLeft(id).subscribe(item => {

                    this.listItem = [];
                    this.load();

                });
                this.listItem = [];
                this.load();

                this.isShowEditMediaDetails = false;

                Swal.fire({
                    title: 'Đã xoá!',
                    // text: 'Your file has been deleted.',
                    icon: 'success'
                });
            }
        });
    }


    addData(title, description, fileInput1, fileInput2, fileInput3) {

        let data = { title, description }


        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('url_video', this.urlVideo);



        if (this.nameImageThumbAdd) {
            for (let i = 0; i < this.nameImageThumbAdd.length; i++) {
                formData.append('recipe_thum', this.nameImageThumbAdd[i], this.nameImageThumbAdd[i].name);
            }
        }

        // if (this.nameImageMainAdd) {
        //     for (let i = 0; i < this.nameImageMainAdd.length; i++) {
        //         formData.append('recipes_main', this.nameImageMainAdd[i], this.nameImageMainAdd[i].name);
        //     }
        // }

        if (this.nameImageMainLeftAdd) {
            for (let i = 0; i < this.nameImageMainLeftAdd.length; i++) {
                formData.append('recipes_left', this.nameImageMainLeftAdd[i], this.nameImageMainLeftAdd[i].name);
            }
        }


        this.recipesService.postData(formData).subscribe(item => {

            if (item.success == true) {
                Swal.fire({
                    title: 'Thêm thành công',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    // this.txtDescriptionAdd = '';
                    this.listItem = [];
                    this.load();
                    fileInput1.value = ''
                    fileInput2.value = ''
                    // fileInput3.value = ''
                    this.txtTitleAdd = ''
                    this.txtDescriptionAdd = ''
                    this.isShowModalAdd = false
                    this.load();
                });
            }
        })
    }

    uploadImageThumbUpdate(event: any) {
        if (event.target.files.length > 3) {
            alert('Bạn chỉ có thể chọn tối đa 3 file.');
            event.target.value = ''
        } else {
            this.nameImageThumbUpdate = event.target.files;
        }
    }

    uploadImageMainUpdate(event: any) {
        if (event.target.files.length > 3) {
            alert('Bạn chỉ có thể chọn tối đa 3 file.');
            event.target.value = ''
        } else {
            this.nameImageMainUpdate = event.target.files;
        }
    }

    uploadImageMainLeftUpdate(event: any) {
        if (event.target.files.length > 3) {
            alert('Bạn chỉ có thể chọn tối đa 3 file.');
            event.target.value = ''
        } else {
            this.nameImageMainLeftUpdate = event.target.files;
        }
    }


    onChange(event) {
        this.statusImageUpdate = event.target.value
    }


    handleChange(event) {

        if (event.target.checked) {
            this.selectedOption = event.target.value

        }

    }

    updateData(id, title, description, position) {


        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('position', position);
        formData.append('status', this.selectedOption);
        formData.append('url_video', this.urlVideo);



        this.recipesService.updateData(formData, id).subscribe(item => {

            Swal.fire({
                title: 'Sửa thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

                this.listItem = [];
                this.load();
                this.isShowEditRecipes = false;

            });
        });


        this.isShowEditRecipes = false
    }

    viewImage(item: any) {
        this.dataView = item
        console.log('this.dataView ', this.dataView)

        item.recipes_imgs.forEach((item: any) => {
            item.url = item.url.replace('Publics/', '');
            if (item.type == 1) {
                this.listImageMain.push(item.url);
            } else {
                this.listImageMainLeft.push(item.url);
            }
        })






        this.isShowViewRecipes = true;
    }

    editImage(item) {
        this.isShowEditRecipes = true;
        this.isDataRecipes = item;
        this.selectedOption = item.status;
        this.urlVideo = item.url_video;
    }

    removeImage(item) {
        Swal.fire({
            title: 'Bạn có muốn xoá không ?',
            // text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                let id = item.id;


                this.recipesService.deleteData(id).subscribe(item => {

                });
                this.listItem = [];
                this.load();

                Swal.fire({
                    title: 'Đã xoá!',
                    // text: 'Your file has been deleted.',
                    icon: 'success'
                });
            }
        });
    }

    saveDetails(item: any, fileInput: any) {
        // alert("run here");

        const file = fileInput.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('position', item.position);
            formData.append('status', item.status);
            formData.append('file', file);


            this.recipesService.updateImageLeft(formData, item.id).subscribe(item => {


                Swal.fire({
                    title: 'Sửa thành công',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {

                    this.listItemDetals = [];
                    this.listItem = [];

                    this.load();
                    this.isShowEditMediaDetails = false;

                });
            });

        } else {

        }
    }


    handleChangeDetails(event: any) {
        // Handle change details logic here
    }

    uploadImageUpdateDetails(event: any, item: any) {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                item.url = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    addImageLeft(item) {

        this.isShowAddImage = true;

    }

    saveImageLeft(item: any, fileInput: any) {


        const file = this.fileToUpload;
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            this.recipesService.addImageLeft(formData, item.id).subscribe(item => {


                Swal.fire({
                    title: 'Thêm thành công',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    this.listItem = [];

                    this.listItemDetals = [];
                    this.load();
                    this.isShowEditMediaDetails = false;
                    this.isShowAddImage = false;

                });
            });

        } else {

        }

    }

    onFileChange(files: FileList) {
        this.labelImport.nativeElement.innerText = Array.from(files)
            .map(f => f.name)
            .join(', ');
        this.fileToUpload = files.item(0);
    }
    saveVideoUrl() {

        let body = {
            url: this.urlVideo
        }
        this.recipesService.updateUrlVideo(body, this.videoItem.id).subscribe(item => {

            Swal.fire({
                title: 'Cập nhật thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

                this.listItemDetals = [];
                this.load();
                this.isShowVideo = false;


            });

        })

    }
}
