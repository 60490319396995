<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <section id="drag-area">
                <div class='main-table-containter'>
                    <div class="d-flex justify-content-between mb-1 box-head">
                        <p-button (click)="showModalAdd()" icon="pi pi-plus" label="Thêm"></p-button>
                    </div>
                    <div class="box-table table-responsive">
                        <table class="table">
                            <thead class="table-dark">
                                <tr>
                                    <td>STT</td>
                                    <td>Tiêu đề</td>
                                    <!-- <td>Nội dung</td> -->
                                    <td>Vị trí</td>
                                    <td>Hành động</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of listItem | paginate: { itemsPerPage: 5, currentPage: p }; index as i">
                                    <td>{{ i + 1 }}</td>
                                    <td [innerHTML]="item.title"></td>
                                    <!-- <td class="txt-line" [innerHTML]="item.content"></td> -->
                                    <td [innerHTML]="item.position"></td>
                                    <td>

                                        <div class="row justify-content-center" style="gap: 10px">
                                            <div class="box">
                                                <p-button class="btn-view" icon="pi pi-eye" (click)="previewNews(item)"
                                                    label="Xem"></p-button>
                                            </div>

                                            <div class="box">
                                                <p-button (click)="editNews(item)" icon="pi pi-cog"
                                                    label="Sửa nội dung"></p-button>

                                            </div>
                                            <div class="box">
                                                <p-button (click)="editImage(item)" icon="pi pi-cog"
                                                    label="Sửa ảnh"></p-button>

                                            </div>
                                            <div class="box">
                                                <p-button class="btn-remove" icon="pi pi-trash"
                                                    (click)="removeNews(item)" label="Xoá"></p-button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="pagination" (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>



<p-dialog header="Sửa sản phẩm" [(visible)]="isShowEditNews" [modal]="true" [breakpoints]="{ '750px': '90vw' }"
    [style]="{ width: '50vw', height: '80vh' }" [draggable]="false" [resizable]="false">
    <div class="mb-3">
        <label for="" class="form-label">Tiêu đề</label>
        <input [(ngModel)]="isDataNews.title" type="text" class="form-control" id="" value="{{isDataNews.title}}">
    </div>

    <div class="mb-3">
        <label for="" class="form-label">Nội dung</label>
        <p-editor [(ngModel)]="isDataNews.content" [style]="{ height: '180px' }"></p-editor>
    </div>

    <div class="mb-3">
        <label for="" class="form-label">Vị trí</label>
        <input [(ngModel)]="isDataNews.position" type="number" class="form-control" id=""
            value="{{isDataNews.position}}">
    </div>


    <div class="d-flex justify-content-end">
        <p-button (click)="updateData(isDataNews.id, isDataNews.title, isDataNews.content, isDataNews.position)"
            label="Lưu" icon="pi pi-check"></p-button>
    </div>
</p-dialog>

<p-dialog header="Hình ảnh" [(visible)]="isShowViewImage" [modal]="true" [breakpoints]="{ '750px': '90vw' }"
    [style]="{ width: '50vw', height: '80vh' }" [draggable]="false" [resizable]="false" (onHide)="closeDialog()">

    <p *ngFor="let slide of sliderView1">
        <img src="https://englishapi.tranchaumood.com/{{slide.url}}" alt="" />
    </p>

    <div class="box-table table-responsive">


        <table class="table">
            <thead class="table-dark">
                <tr>
                    <td>Hình ảnh</td>
                    <td>Vị trí</td>
                    <td>Trạng thái</td>
                    <td>Action</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.listItemDetals;index as i">
                    <td>
                        <span class="d-block table-img view-edit-img">
                            <img src="https://englishapi.tranchaumood.com/{{item.url}}" alt="" />
                        </span>
                    </td>
                    <td>
                        <input [(ngModel)]="item.position" type="number" min="1" class="form-control" id=""
                            value="{{item.position}}">
                    </td>
                    <td>
                        <div class="">

                            <div class="form-check form-check-inline">
                                <input class="form-check-input cursor-pointer" value="1"
                                    (change)="handleChangeDetails($event)" [checked]="item.status == 1" type="radio"
                                    name="inlineRadioOptions{{i+1}}" id="inlineRadio{{i+1}}">
                                <label class="form-check-label cursor-pointer" for="inlineRadio{{i+1}}">Hoạt
                                    động</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input cursor-pointer" value="0"
                                    (change)="handleChangeDetails($event)" [checked]="item.status == 0" type="radio"
                                    name="inlineRadioOptions{{i+1}}" id="inlineRadio{{i+1}}">
                                <label class="form-check-label cursor-pointer" for="inlineRadio{{i+1}}">Không hoạt
                                    dộng</label>
                            </div>
                        </div>
                    </td>
                    <td>
                        <p-button label="Lưu" (click)="updateDataDetails(item, item.id, item.position, item.status)"
                            icon="pi pi-check"></p-button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>


</p-dialog>

<p-dialog header="Thêm tin tức" [(visible)]="isCreate" [modal]="true" [breakpoints]="{ '750px': '90vw' }"
    [style]="{ width: '40vw', height: '80vh' }" [draggable]="false" [resizable]="false" (onHide)="resetForm()">
    <div class="mb-2">
        <h4 class="form-section"><i class="feather ft-eye"></i> Thông tin chung</h4>

        <div class="mb-2">
            <label for="" class="form-label">Tiêu đề *</label>
            <p>
                <input [(ngModel)]="txtTitleAdd" type="text" class="form-control" id="">
            </p>
            <div *ngIf="!txtTitleAdd" class="text-danger">Title là bắt buộc.</div>

        </div>

        <div class="mb-2">
            <label for="" class="form-label">Ảnh Thumnal *</label>
            <div class="custom-file">
                <input #addImageLeftInput type="file" accept="image/*" class="custom-file-input"
                    formControlName="importFile" id="importFile" (change)="onFileChange($event.target.files,5)">
                <label class="custom-file-label" #labelImport5 for="inputGroupFile01">Chọn file</label>
                <div *ngIf="!fileToUpload5" class="text-danger">Ảnh Thumnal là bắt buộc.</div>

            </div>
        </div>
        <h4 class="form-section"><i class="feather ft-edit-2"></i>Nội dung 1</h4>

        <div class="mb-2">
            <label for="" class="form-label">Nội dung</label>
            <p-editor [(ngModel)]="txtContent1" [style]="{ height: '100px' }"></p-editor>
        </div>
        <div class="mb-2">
            <label for="" class="form-label">Hình ảnh</label>
            <div class="custom-file">
                <input #addImageLeftInput type="file" accept="image/*" class="custom-file-input"
                    formControlName="importFile" id="importFile1" multiple
                    (change)="onFileChange($event.target.files,1)">
                <label class="custom-file-label" #labelImport1 for="inputGroupFile01">Chọn file</label>
            </div>
        </div>
        <h4 class="form-section"><i class="feather ft-edit-2"></i>Nội dung 2</h4>

        <div class="mb-2">
            <label for="" class="form-label">Nội dung</label>
            <p-editor [(ngModel)]="txtContent2" [style]="{ height: '100px' }"></p-editor>
        </div>
        <div class="mb-2">
            <label for="" class="form-label">Hình ảnh</label>
            <div class="custom-file">
                <input #addImageLeftInput type="file" accept="image/*" class="custom-file-input"
                    formControlName="importFile" id="importFile2" multiple
                    (change)="onFileChange($event.target.files,2)">
                <label class="custom-file-label" #labelImport2 for="inputGroupFile01">Chọn file</label>
            </div>
        </div>
        <h4 class="form-section"><i class="feather ft-edit-2"></i>Nội dung 3</h4>

        <div class="mb-2">
            <label for="" class="form-label">Nội dung</label>
            <p-editor [(ngModel)]="txtContent3" [style]="{ height: '100px' }"></p-editor>
        </div>
        <div class="mb-2">
            <label for="" class="form-label">Hình ảnh</label>
            <div class="custom-file">
                <input #addImageLeftInput type="file" accept="image/*" class="custom-file-input"
                    formControlName="importFile" id="importFile3" multiple
                    (change)="onFileChange($event.target.files,3)">
                <label class="custom-file-label" #labelImport3 for="inputGroupFile01">Chọn file</label>
            </div>
        </div>
        <h4 class="form-section"><i class="feather ft-edit-2"></i>Nội dung 4</h4>

        <div class="mb-2">
            <label for="" class="form-label">Nội dung</label>
            <p-editor [(ngModel)]="txtContent4" [style]="{ height: '100px' }"></p-editor>
        </div>
        <div class="mb-2">
            <label for="" class="form-label">Hình ảnh</label>
            <div class="custom-file">
                <input #addImageLeftInput type="file" accept="image/*" class="custom-file-input"
                    formControlName="importFile" id="importFile4" multiple
                    (change)="onFileChange($event.target.files,4)">
                <label class="custom-file-label" #labelImport4 for="inputGroupFile01">Chọn file</label>
            </div>
        </div>

    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="submitCreateNews()" icon="pi pi-check" label="Lưu" [disabled]="!canSubmit()"></p-button>
    </ng-template>

</p-dialog>

<p-dialog [header]="selectedItem ? 'Preview: ' + selectedItem.title : 'Cập nhật ảnh tin tức'" [(visible)]="isView"
    [modal]="true" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '90rem', height: '80vh' }"
    [draggable]="false" [resizable]="false" (onHide)="resetView()">
    <section id="content">
        <div class="container">
            <section id="single-news">

                <h2 style="text-align: center;">
                    {{titleView}}
                </h2>

                <div [innerHTML]="content1"></div>
                <div *ngIf="imgContent1 && imgContent1.length > 0">
                    <p style="text-align: center;" *ngFor="let item of imgContent1">
                        <!-- <img [src]="baseUrl + '/' + item.url" alt=""> -->
                        <img src="{{item.url}}" alt="" style="max-width: 100%; height: auto;">

                    </p>
                </div>

                <div [innerHTML]="content2"></div>
                <div *ngIf="imgContent2 && imgContent2.length > 0">
                    <p style="text-align: center;" *ngFor="let item of imgContent2">
                        <img src="{{item.url}}" alt="" style="max-width: 100%; height: auto;">
                    </p>
                </div>

                <div [innerHTML]="content3"></div>
                <div *ngIf="imgContent3 && imgContent3.length > 0">
                    <p style="text-align: center;" *ngFor="let item of imgContent3">
                        <img src="{{item.url}}" alt="" style="max-width: 100%; height: auto;">
                    </p>
                </div>

                <div [innerHTML]="content4"></div>
                <div *ngIf="imgContent4 && imgContent4.length > 0">
                    <p style="text-align: center;" *ngFor="let item of imgContent4">
                        <img src="{{item.url}}" alt="" style="max-width: 100%; height: auto;">
                    </p>
                </div>
            </section>
        </div>
    </section>



</p-dialog>

<p-dialog [header]="selectedItem ? 'Cập nhật nội dung tin tức: ' + selectedItem.title : 'Cập nhật ảnh tin tức'"
    [(visible)]="isUpdate" [modal]="true" [breakpoints]="{ '750px': '90vw' }"
    [style]="{ width: '40vw', height: '80vh' }" [draggable]="false" [resizable]="false" (onHide)="resetUpdate()">
    <div class="mb-2">
        <h4 class="form-section"><i class="feather ft-eye"></i> Thông tin chung</h4>
        <div class="mb-2">
            <label for="" class="form-label">Tiêu đề *</label>
            <p>
                <input [(ngModel)]="txtTitleAdd" type="text" class="form-control" id="">
            </p>
            <div *ngIf="!txtTitleAdd" class="text-danger">Title là bắt buộc.</div>
        </div>

        <div class="row mb-2">
            <div class="col-lg-6 position">
                <label class="form-label">Vị trí</label>
                <input type="number" class="form-control" id="txtarea3" [(ngModel)]="selectedItemPosition"
                    value="{{selectedItemPosition}}">
            </div>

            <div class="col-lg-6">
                <p class="form-label">Trạng thái</p>

                <div class="form-check form-check-inline">
                    <input class="form-check-input cursor-pointer" value="1" (change)="handleChange($event)"
                        [checked]="selectedStatus == 1" type="radio" name="inlineRadioOptions" id="inlineRadio1">
                    <label class="form-check-label cursor-pointer" for="inlineRadio1">Hoạt động</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input cursor-pointer" value="0" (change)="handleChange($event)"
                        [checked]="selectedStatus == 0" type="radio" name="inlineRadioOptions" id="inlineRadio2">
                    <label class="form-check-label cursor-pointer" for="inlineRadio2">Không hoạt động</label>
                </div>


            </div>
        </div>

        <h4 class="form-section"><i class="feather ft-edit-2"></i>Nội dung 1</h4>

        <div class="mb-2">
            <label for="" class="form-label">Nội dung</label>
            <p-editor [(ngModel)]="txtContent1" [style]="{ height: '100px' }"></p-editor>
        </div>

        <h4 class="form-section"><i class="feather ft-edit-2"></i>Nội dung 2</h4>

        <div class="mb-2">
            <label for="" class="form-label">Nội dung</label>
            <p-editor [(ngModel)]="txtContent2" [style]="{ height: '100px' }"></p-editor>
        </div>

        <h4 class="form-section"><i class="feather ft-edit-2"></i>Nội dung 3</h4>
        <div class="mb-2">
            <label for="" class="form-label">Nội dung</label>
            <p-editor [(ngModel)]="txtContent3" [style]="{ height: '100px' }"></p-editor>
        </div>
        <h4 class="form-section"><i class="feather ft-edit-2"></i>Nội dung 4</h4>
        <div class="mb-2">
            <label for="" class="form-label">Nội dung</label>
            <p-editor [(ngModel)]="txtContent4" [style]="{ height: '100px' }"></p-editor>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <p-button (click)="submitEditNews()" icon="pi pi-check" label="Lưu" [disabled]="!canEdit()"></p-button>
    </ng-template>

</p-dialog>

<p-dialog [header]="selectedItem ? 'Cập nhật ảnh tin tức: ' + selectedItem.title : 'Cập nhật ảnh tin tức'"
    [(visible)]="isUpdateImage" [modal]="true" [breakpoints]="{ '750px': '90vw' }"
    [style]="{ width: '85vw', height: '80vh' }" [draggable]="false" [resizable]="false">

    <div class="flex justify-content-center mb-1">
        <p-button class="btn-save" icon="pi pi-check" (click)="addImage(selectedItem)" label="Thêm ảnh"></p-button>
    </div>
    <div class="box-table table-responsive">
        <table class="table">
            <thead class="table-dark">
                <tr>
                    <td>Hình ảnh</td>
                    <td>Vị trí</td>
                    <td>Trạng thái</td>
                    <td>Loại</td>
                    <td>Thay ảnh</td>
                    <td>Hành động</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of this.listItemDetals;index as i">
                    <td>
                        <span class="d-block table-img view-edit-img">
                            <!-- <img src="{{item.url}}" alt="" > -->
                            <img src="{{item.url}}" alt="" style="width: 100px; height: 100px; object-fit: cover;">

                            <!-- <img [src]="baseUrl + '/' + item.url" alt="" style="width: 100px; height: 100px; object-fit: cover;"> -->

                        </span>
                    </td>
                    <td>
                        <input *ngIf="item.type !== 0" [(ngModel)]="item.position" type="number" min="1"
                            class="form-control" id="" value="{{item.position}}">
                    </td>
                    <td>
                        <div *ngIf="item.type !== 0" class="">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input cursor-pointer" value="1"
                                    (change)="handleChangeStatusImage($event, item)" [checked]="item.status == 1"
                                    type="radio" name="inlineRadioOptions{{i+1}}" id="inlineRadio1">
                                <label class="form-check-label cursor-pointer" for="inlineRadio1">Hoạt động</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input cursor-pointer" value="0"
                                    (change)="handleChangeStatusImage($event, item)" [checked]="item.status == 0"
                                    type="radio" name="inlineRadioOptions{{i+1}}" id="inlineRadio2">
                                <label class="form-check-label cursor-pointer" for="inlineRadio2">Không hoạt
                                    động</label>
                            </div>
                        </div>
                    </td>
                    <td>{{item.kind}}</td>

                    <td>
                        <input #fileImage accept="image/*" (change)="uploadImageUpdateDetails($event, item)" type="file"
                            id="">
                    </td>
                    <td>
                        <div class="row justify-content-center" style="gap: 10px">
                            <div class="box">
                                <p-button class="btn-save" icon="pi pi-check" (click)="submitEditImage(item, fileImage)"
                                    label="Lưu"></p-button>
                            </div>
                            <div class="box" *ngIf="item.type != 0">
                                <p-button class="btn-remove" icon="pi pi-trash" (click)="removeImage(item)"
                                    label="Xoá"></p-button>
                            </div>
                        </div>

                    </td>
                </tr>
            </tbody>
        </table>

    </div>

</p-dialog>

<p-dialog header="Thêm mới ảnh" [(visible)]="isAddImage" [modal]="true" [breakpoints]="{ '750px': '90vw' }"
    [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false" (onHide)="resetAddImage()">
    <div class="mb-2">
        <h4 class="form-section"><i class="feather ft-eye"></i> Thông tin chung</h4>

        <div class="row mb-2">
            <div class="col-lg-6 position">
                <div class="form-group">
                    <label for="" class="form-label">Nội dung</label>
                    <select class="form-control" id="basicSelect" [(ngModel)]="selectedOption" (change)="onSelectChange($event)">
                        <option *ngFor="let option of options" [value]="option.value">{{option.name}}</option>
                    </select>
                </div>

            </div>
            <div class="col-lg-6 position">
                <div class="form-group">
                    <label for="" class="form-label">Hình ảnh *</label>
                    <div class="custom-file">
                        <input #addImageFile type="file" accept="image/*" class="custom-file-input"
                            formControlName="importFile" id="importFile6"
                            (change)="onFileChange($event.target.files,6)">
                        <label class="custom-file-label" #labelImport6 for="inputGroupFile01">Chọn file</label>
                        <div *ngIf="!fileToUpload6" class="text-danger">Ảnh là bắt buộc.</div>

                    </div>
                </div>
            </div>
        </div>
        <h4 class="form-section"><i class="feather ft-eye"></i>Hình ảnh</h4>

        <div class="row mb-2 justify-content-center">
            <div class="col-12 text-center">
              <img *ngIf="selectedImage" [src]="selectedImage" alt="" id="selectedImage" class="img-fluid">
            </div>
          </div>
    </div>
    <div class="d-flex justify-content-end">
        <p-button (click)="submitAddImage(selectedItem,addImageFile)" [disabled]="!canSubmitAddImage()" label="Lưu"> </p-button>
    </div>
</p-dialog>