import {Component, OnInit} from '@angular/core';
import Swal from "sweetalert2";
import {UserService} from '../../_services/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit{

  constructor(private userService: UserService) {
  }


  public breadcrumb: any;
  isShowModalAdd: boolean;
  txtUsernameAdd: string = '';
  txtPasswordAdd: string = '';



  ngOnInit() {
    this.breadcrumb = {
      'mainlabel': 'Quản lý người dùng',
      'links': [
        {
          'name': 'Home',
          'isLink': true,
          'link': '/changelog'
        },
        {
          'name': 'Quản lý người dùng',
          'isLink': false,
          'link': '#'
        },
      ]
    };
  }


  showModalAdd() {
    this.isShowModalAdd = true;
  }


  addData(username, password) {

    if (username && password) {
      // let myfile = this.nameImageAdd;

      this.userService.postData(username, password, '/auth/login').subscribe(item => {

        Swal.fire({
          title: 'Thêm thành công',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok'
        }).then((result) => {
          // fileInput.value = '';
          this.txtUsernameAdd = '';
          this.txtPasswordAdd = '';
          // this.listItem = [];
          // this.load();
          this.isShowModalAdd = false;
        });
      });

      console.log(username, password)


    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Lỗi !',
        text: 'Vui lòng nhập đủ thông tin username và password',
        // footer: '<a href="#">Why do I have this issue?</a>'
      });
    }


  }

}
