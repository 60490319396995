<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <section id="drag-area">
                <div class='main-table-containter'>
                    <div class="d-flex justify-content-between mb-1 box-head">
                        <p-button (click)="save()" icon="pi pi-plus" label="Lưu"></p-button>

                    </div>

                    <div class="list_introduce">
                        <div class="item_introduce">
                            <div class="content_item d-flex my-2">
                                <p class="img_content" *ngIf="base64Image1">
                                     <img [src]="'data:image/jpeg;base64,' + base64Image1" alt="Image Preview" class="w-100">
                                </p>
                                <div class="txt_content">
                                    <div class="">
                                        <p-editor [(ngModel)]="content1" [style]="{ height: '250px' }"></p-editor>

                                    </div>
                                    <div class="">
                                        <div class="form-group">
                                            <label for="" class="form-label">Hình ảnh</label>
                                            <div class="custom-file">
                                                <input #addImageFile type="file" accept="image/*"
                                                       class="custom-file-input" formControlName="importFile"
                                                       id="importFile1" (change)="onFileChange($event.target.files,1)">
                                                <label class="custom-file-label" #labelImport1
                                                       for="inputGroupFile01">Chọn file</label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="item_introduce">
                            <div class="content_item d-flex my-2">
                                <p class="img_content" *ngIf="base64Image2">
                                    <img [src]="'data:image/jpeg;base64,' + base64Image2" alt="Image Preview" class="w-100" >
                               </p>
                                <div class="txt_content">
                                    <div class="">
                                        <p-editor [(ngModel)]="content2" [style]="{ height: '250px' }"></p-editor>

                                    </div>
                                    <div class="">
                                        <div class="form-group">
                                            <label for="" class="form-label">Hình ảnh</label>
                                            <div class="custom-file">
                                                <input #addImageFile type="file" accept="image/*"
                                                       class="custom-file-input" formControlName="importFile"
                                                       id="importFile2" (change)="onFileChange($event.target.files,2)">
                                                <label class="custom-file-label" #labelImport2
                                                       for="inputGroupFile01">Chọn file</label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item_introduce">
                            <div class="content_item d-flex my-2">
                                <p class="img_content" *ngIf="base64Image3">
                                    <img [src]="'data:image/jpeg;base64,' + base64Image3" alt="Image Preview" class="w-100" >
                               </p>
                                <div class="txt_content">
                                    <div class="">
                                        <p-editor [(ngModel)]="content3" [style]="{ height: '250px' }"></p-editor>

                                    </div>
                                    <div class="">
                                        <div class="form-group">
                                            <label for="" class="form-label">Hình ảnh</label>
                                            <div class="custom-file">
                                                <input #addImageFile type="file" accept="image/*"
                                                       class="custom-file-input" formControlName="importFile"
                                                       id="importFile3" (change)="onFileChange($event.target.files,3)">
                                                <label class="custom-file-label" #labelImport3
                                                       for="inputGroupFile01">Chọn file</label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item_introduce">
                            <div class="content_item d-flex my-2">
                                <p class="img_content" *ngIf="base64Image4">
                                    <img [src]="'data:image/jpeg;base64,' + base64Image4" alt="Image Preview" class="w-100" >
                               </p>
                                <div class="txt_content">
                                    <div class="">
                                        <p-editor [(ngModel)]="content4" [style]="{ height: '250px' }"></p-editor>

                                    </div>
                                    <div class="">
                                        <div class="form-group">
                                            <label for="" class="form-label">Hình ảnh</label>
                                            <div class="custom-file">
                                                <input #addImageFile type="file" accept="image/*"
                                                       class="custom-file-input" formControlName="importFile"
                                                       id="importFile4" (change)="onFileChange($event.target.files,4)">
                                                <label class="custom-file-label" #labelImport4
                                                       for="inputGroupFile01">Chọn file</label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="item_introduce">
                            <div class="content_item d-flex my-2">
                                <p class="img_content" *ngIf="base64Image5">
                                    <img [src]="'data:image/jpeg;base64,' + base64Image5" alt="Image Preview" class="w-100" >
                               </p>
                                <div class="txt_content">
                                    <div class="">
                                        <p-editor [(ngModel)]="content5" [style]="{ height: '250px' }"></p-editor>

                                    </div>
                                    <div class="">
                                        <div class="form-group">
                                            <label for="" class="form-label">Hình ảnh</label>
                                            <div class="custom-file">
                                                <input #addImageFile type="file" accept="image/*"
                                                       class="custom-file-input" formControlName="importFile"
                                                       id="importFile5" (change)="onFileChange($event.target.files,5)">
                                                <label class="custom-file-label" #labelImport5
                                                       for="inputGroupFile01">Chọn file</label>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </section>
        </div>
    </div>
</div>
