import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {MessageService} from 'primeng/api';
import {TopBannerService} from '../../_services/top-banner.service';
import {
    debounce
} from 'lodash';
import {environment} from '../../../environments/environment';


@Component({
    selector: 'app-top-banner',
    templateUrl: './top-banner.component.html',
    styleUrls: ['./top-banner.component.css'],
    providers: [MessageService]
})
export class TopBannerComponent implements OnInit {

    constructor(private messageService: MessageService,
                private topBannerService: TopBannerService) {

    }

    public breadcrumb: any;

    isShowModalAdd: boolean;
    isShowViewImage: boolean;
    isShowEditImage: boolean;

    isDataImage: any = {};

    txtDescriptionAdd: string = '';
    nameImageAdd: any;

    urlImageEdit: string = '';


    nameImageUpdate: any;
    statusImageUpdate: any;

    selectedOption: any;


    txtUpdateDescription: string = '';

    listItem = [];
    p: any;
    valueSearch: string = '';
    listTmp: any = [];
    filterArr: any = []

    ngOnInit() {
        this.breadcrumb = {
            'mainlabel': 'Top Banner',
            'links': [
                {
                    'name': 'Home',
                    'isLink': true,
                    'link': '/changelog'
                },
                {
                    'name': 'Top banner',
                    'isLink': false,
                    'link': '#'
                },
            ]
        };

        this.load();
    }

    load() {
        this.topBannerService.getTopBanner().subscribe(item => {
            item.data.forEach(item => {
                item.created_at = this.convertToDdMmYyyy(item.created_at);
                item.updated_at = this.convertToDdMmYyyy(item.updated_at);
                this.listItem.push(item);
                this.listTmp = this.listItem;

            });
        });
    }

    search = debounce((event) => {

        let value = event.target.value;
        let key = event.keyCode || event.charCode;
        if (key == 8 || key == 46) {
            this.listItem = this.listTmp;
        }
        if (value !== '') {
            this.filterArr = this.listItem.filter(item => item.description.toLowerCase().includes(value.toLowerCase()));

            this.listItem = this.filterArr;
        } else {
            this.listItem = this.listTmp;
        }
    }, 500);

    filterStatus(event: any) {
        let value = event.target.value
        if (value == 'all') {
            this.listItem = this.listTmp
        } else if (value == '1') {
            this.listItem = this.listTmp
            this.filterArr = this.listItem.filter(item => item.status == 1);
            this.listItem = this.filterArr;
        } else if (value == '0') {
            this.listItem = this.listTmp
            this.filterArr = this.listItem.filter(item => item.status == 0);
            this.listItem = this.filterArr;
        }
    }

    convertToDdMmYyyy(timestamp) {
        const dateObject = new Date(timestamp);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
    }

    showModalAdd() {
        this.isShowModalAdd = true;
    }


    uploadImageAdd(event: Event) {

        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;

        if (fileList) {
            this.nameImageAdd = fileList[0];
        }
    }

    uploadImageUpdate(event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageUpdate = fileList[0];
        }
    }

    addData(description, fileInput) {
        let accesstoken = localStorage.getItem('accesstoken');

        if (description && this.nameImageAdd) {
            let myfile = this.nameImageAdd;

            this.topBannerService.postData(accesstoken, myfile, description, '/top_banner').subscribe(item => {

                Swal.fire({
                    title: 'Thêm thành công',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    fileInput.value = '';
                    this.txtDescriptionAdd = '';
                    this.listItem = [];
                    this.load();
                    this.isShowModalAdd = false;
                });
            });


        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Lỗi !',
                text: 'Vui lòng nhập đủ thông tin mô tả và hình ảnh',
                // footer: '<a href="#">Why do I have this issue?</a>'
            });
        }


    }

    onChange(event) {
        this.statusImageUpdate = event.target.value;
    }


    handleChange(event) {

        if (event.target.checked) {
            this.selectedOption = event.target.value;

        }

    }

    updateData(id, description, position, fileInput) {
        let accesstoken = localStorage.getItem('accesstoken');

        let tmpStatus = Number(this.selectedOption)
        let tmpImage = this.nameImageUpdate

        let data = {id, description, position, tmpStatus, tmpImage}

        this.topBannerService.updateData(accesstoken, data, '/top_banner').subscribe(item => {
            Swal.fire({
                title: 'Sửa thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

                this.nameImageUpdate = '';
                fileInput.value = '';
                this.listItem = [];
                this.load();
                this.isShowEditImage = false;

            });
        });


    }

    viewImage(url) {
        //alert(url);
        url = url.replace('Publics/', '');
        this.urlImageEdit = environment.URL + '/'+url;


        this.isShowViewImage = true;
    }

    editImage(item) {
        this.isShowEditImage = true;
        this.isDataImage = item;
        this.selectedOption = item.status;
    }

    removeImage(item) {
        let accesstoken = localStorage.getItem('accesstoken');
        Swal.fire({
            title: 'Bạn có muốn xoá không ?',
            // text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                let id = item.id;

                this.topBannerService.deleteData(accesstoken, id, '/top_banner').subscribe(item => {

                });
                this.listItem = [];
                this.load();

                Swal.fire({
                    title: 'Đã xoá!',
                    // text: 'Your file has been deleted.',
                    icon: 'success'
                });
            }
        });
    }
}
