import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class MediaService {

    requestOptions: any;


    constructor(private _http: HttpClient) {
        // this.requestOptions = {
        //     headers: new HttpHeaders({
        //         // 'Authorization': `Bearer ${this.accesstoken}`,
        //         'Content-Type': 'application/json'
        //     })
        // };
    }

    getMedia(accesstoken, url) {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        let mediaResult = this._http
            .get(`${environment.URL}${url}`, requestOptions)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );

        console.log('${environment.URL}${url} ', `${environment.URL}${url}/`);

        return mediaResult;
    }

    postData(accesstoken, data, url): Observable<any> {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('url_media', data.link);
        formData.append('thum', data.thum);
        // let dataJson = {
        //     "title":data.title,
        //     "description":data.description,
        //     "url_media":data.url_media,
        //     "thum":data.thum }

        

        let result = this._http.post<any>(`${environment.URL}${url}`, formData, requestOptions);
        return result
    }

    deleteData(accesstoken, id, url) {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        let result = this._http.delete(`${environment.URL}${url}/${id}`, requestOptions).pipe(map((res: any) => {
            return res;
        }));
        return result;
    }

    updateData(accesstoken, data, url) {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('position', data.position);
        formData.append('status', data.tmpStatus);
        formData.append('thum', data.tmpImage);
        formData.append('url_media', data.url_media);
        // let dataJson = {
        //     "title":data.title,
        //     "description":data.description,
        //     "position":data.position,
        //     "status":data.status,
        //     "thum":data.thum,

        // }
        console.log('${environment.URL}${url}/${id} ', `${environment.URL}${url}/${data.id}`);

        let result = this._http.put(`${environment.URL}${url}/${data.id}`, formData, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }
}
