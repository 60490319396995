<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <section id="drag-area">

                <div class='main-table-containter'>

                    <div class="d-flex justify-content-between mb-1 box-head">
                        <p-button (click)="showModalAdd()" icon="pi pi-plus" label="Thêm"></p-button>


                        
                        <p-dialog header="Thêm sản phẩm" [(visible)]="isShowModalAdd" [modal]="true"
                                  [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw', height: '80vh' }"
                                  [draggable]="false" [resizable]="false">
                            <div class="mb-2">
                                <label for="" class="form-label">Tiêu đề</label>
                                <input [(ngModel)]="txtTitleAdd" type="text" class="form-control" id="">
                            </div>
                            <div class="mb-3">
                                <p>Chọn ảnh thumbnail</p>
                                <input #fileInputAdd accept="image/*" (change)="uploadImageAdd($event)" type="file"
                                       id="formFile">
                            </div>

                            <div class="row mb-3">
                                <div class="col-lg-4 position">
                                    <p>Chọn ảnh chi tiết 1</p>
                                    <input #fileInputAdd1 accept="image/*" (change)="uploadImageAdd1($event)"
                                           type="file"
                                           multiple
                                           id="formFile1">
                                </div>

                                <div class="col-lg-4 position">
                                    <p>Chọn ảnh chi tiết 2</p>
                                    <input #fileInputAdd2 accept="image/*" (change)="uploadImageAdd2($event)"
                                           type="file"
                                           multiple
                                           id="formFile1">
                                </div>

                                <div class="col-lg-4">
                                    <p>Chọn ảnh chi tiết 3</p>
                                    <input #fileInputAdd3 accept="image/*" (change)="uploadImageAdd3($event)"
                                           type="file"
                                           multiple
                                           id="formFile1">
                                </div>
                            </div>


                            <div class="mb-2">
                                <label for="" class="form-label">Giá</label>
                                <input [(ngModel)]="txtPriceAdd" type="number" min="0" class="form-control" id="">
                            </div>
                            <div class="mb-2">
                                <label for="" class="form-label">Giới thiệu</label>
                                <p-editor [(ngModel)]="txtIntroduceAdd" [style]="{ height: '180px' }"></p-editor>
                            </div>
                            <div class="mb-2">
                                <label for="" class="form-label">Mô tả</label>
                                <p-editor [(ngModel)]="txtDescriptionAdd" [style]="{ height: '180px' }"></p-editor>
                            </div>
                            <div class="mb-2">
                                <label for="" class="form-label">Extra</label>
                                <p-editor [(ngModel)]="txtExtraAdd" [style]="{ height: '180px' }"></p-editor>
                            </div>

                            <div class="d-flex justify-content-end">
                                <p-button
                                        (click)="addData(txtTitleAdd, fileInputAdd, fileInputAdd1, fileInputAdd2, fileInputAdd3, txtPriceAdd, txtIntroduceAdd, txtDescriptionAdd, txtExtraAdd)"
                                        label="Lưu"
                                        icon="pi pi-check"></p-button>
                            </div>
                        </p-dialog>
                        <div class="d-flex box-filter" style="gap: 10px">

                            <div class="checkbox-container">
                                <label class="checkbox-label">
                                    <input type="checkbox"  [(ngModel)]="isShowPrice"  (change)="onCheckboxChange($event)" class="large-checkbox" />
                                    <span class="large-text">Hiển thị giá bán</span>
                                </label>
                            </div>
                            <div class="radio-input">
                                <label>
                                    <input (change)="filterStatus($event)" value="all" name="value-radio" id="value-1" type="radio" checked>
                                    <span>Tất cả</span>
                                </label>
                                <label>
                                    <input (change)="filterStatus($event)" value="1" name="value-radio" id="value-2" type="radio">
                                    <span>Hoạt động</span>
                                </label>
                                <label>
                                    <input (change)="filterStatus($event)" value="0" name="value-radio" id="value-3" type="radio">
                                    <span>Không hoạt động</span>
                                </label>
                                <span class="selection"></span>
                            </div>
                            <span class="p-input-icon-right">
                                <i class="pi pi-search"></i>
                                <input type="text" pInputText placeholder="Nhập tên sản phẩm" (keydown)="search($event)"
                                       [(ngModel)]="valueSearch"/>
                            </span>
                        </div>
                    </div>

                    <div class="box-table table-responsive">


                        <table class="table">
                            <thead class="table-dark">
                            <tr>
                                <td>ID</td>
                                <td>Tên sản phẩm</td>
                                <td>Giới thiệu</td>
                                <td>Mô tả</td>
                                <td>Extra</td>
                                <td>Giá</td>
                                <td>Vị trí</td>
                                <td>Trạng thái</td>
                                <td>Ngày</td>
                                <td>Action</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of listItem | paginate: { itemsPerPage: 5, currentPage: p };index as i ">
                                <td>{{ i + 1 }}</td>
                                <td class="table-cell table-cell-medium" [innerHTML]="item.title"></td>
                                <td class="table-cell table-cell-long" [innerHTML]="item.introduce"></td>
                                <td class="table-cell table-cell-long" [innerHTML]="item.description"></td>
                                <td class="table-cell table-cell-long" [innerHTML]="item.extra"></td>
                                <td [innerHTML]="item.price"></td>
                                <td [innerHTML]="item.position"></td>
                                <td>
                                    <span *ngIf="item.status == 1; else elseBlock">Hoạt động</span>
                                    <ng-template #elseBlock>
                                        <span>Không hoạt dộng</span>
                                    </ng-template>
                                </td>
                                <td>
                                    <p>Ngày tạo: {{ item.created_at }}</p>
                                    <p class="mb-0">Ngày cập nhật: {{ item.updated_at }}</p>
                                </td>
                                <td>

                                    <div class="row justify-content-center" style="gap: 10px">
                                        <div class="box">
                                            <p-button class="btn-view" icon="pi pi-eye"
                                                      (click)="viewMedia(item.url_thum, item.detail_images)"
                                                      label="Xem"></p-button>
                                            <p-dialog header="Hình ảnh" [(visible)]="isShowViewMedia" [modal]="true"
                                                      [breakpoints]="{ '750px': '90vw' }"
                                                      [style]="{ width: '50vw', height: '80vh' }"
                                                      [draggable]="false" [resizable]="false">
                                                <p class="text-left">Hình thumbnail</p>
                                                <p class="text-center view-edit-img">
                                                    <img src="{{urlThumView}}"
                                                         alt="">
                                                </p>

                                                <div *ngFor="let item of listUrlMediaDetailEdit;index as i">
                                                    <p class="text-left">Hình chi tiết {{ i + 1 }}</p>
                                                    <p class="text-center view-edit-img">
                                                        <img src="{{item.url}}"
                                                             alt="">
                                                    </p>
                                                </div>


                                            </p-dialog>
                                        </div>

                                        <div class="box">
                                            <p-button (click)="editMedia(item)" icon="pi pi-cog" label="Sửa"></p-button>
                                        </div>
                                        <div class="box">
                                            <p-button (click)="editMediaDetails(item)" icon="pi pi-cog"
                                                      label="Sửa ảnh"></p-button>
                                        </div>
                                        <div class="box">
                                            <p-button class="btn-remove" icon="pi pi-trash" (click)="removeMedia(item)"
                                                      label="Xoá"></p-button>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="pagination" (pageChange)="p = $event"></pagination-controls>

                    </div>

                </div>

            </section>
        </div>
    </div>
</div>

<p-dialog header="Sửa sản phẩm" [(visible)]="isShowEditMedia" [modal]="true"
          [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw', height: '80vh' }"
          [draggable]="false" [resizable]="false">
    <div class="mb-3">
        <label for="" class="form-label">Tiêu đề</label>
        <input [(ngModel)]="isDataMedia.title" type="text" class="form-control" id="" value="{{isDataMedia.title}}">
    </div>

    <div class="mb-3">
        <p>Chọn ảnh thumbnail</p>
        <input #fileInputUpdate accept="image/*" (change)="uploadImageUpdate($event)" type="file" id="">
    </div>

    <!--    <div class="row mb-3">-->
    <!--        <div class="col-lg-4 position">-->
    <!--            <p>Chọn ảnh chi tiết 1</p>-->
    <!--            <input #fileInputUpdate1 accept="image/*" (change)="uploadImageUpdate1($event)" type="file" id="">-->
    <!--        </div>-->
    <!--        <div class="col-lg-4 position">-->
    <!--            <p>Chọn ảnh chi tiết 2</p>-->
    <!--            <input #fileInputUpdate2 accept="image/*" (change)="uploadImageUpdate2($event)" type="file" id="">-->
    <!--        </div>-->
    <!--        <div class="col-lg-4">-->
    <!--            <p>Chọn ảnh chi tiết 3</p>-->
    <!--            <input #fileInputUpdate3 accept="image/*" (change)="uploadImageUpdate3($event)" type="file" id="">-->
    <!--        </div>-->
    <!--    </div>-->


    <div class="mb-3">
        <label for="" class="form-label">Giá</label>
        <input [(ngModel)]="isDataMedia.price" type="text" class="form-control" id="" value="{{isDataMedia.price}}">
    </div>

    <div class="mb-3">
        <label for="txtarea3" class="form-label">Vị trí</label>
        <input type="number" class="form-control" id="txtarea3" [(ngModel)]="isDataMedia.position"
               value="{{isDataMedia.position}}">
    </div>

    <div class="mb-3">
        <p class="form-label">Trạng thái</p>

        <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" value="1" (change)="handleChange($event)"
                   [checked]="selectedOption == 1" type="radio" name="inlineRadioOptions" id="inlineRadio1">
            <label class="form-check-label cursor-pointer" for="inlineRadio1">Hoạt động</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" value="0" (change)="handleChange($event)"
                   [checked]="selectedOption == 0" type="radio" name="inlineRadioOptions" id="inlineRadio2">
            <label class="form-check-label cursor-pointer" for="inlineRadio2">Không hoạt dộng</label>
        </div>
    </div>

    <div class="mb-3">
        <label for="" class="form-label">Giới thiệu</label>
        <p-editor [(ngModel)]="isDataMedia.introduce" [style]="{ height: '180px' }" data-value=""></p-editor>
    </div>
    <div class="mb-3">
        <label for="" class="form-label">Mô tả</label>
        <p-editor [(ngModel)]="isDataMedia.description" [style]="{ height: '180px' }" data-value=""></p-editor>
    </div>
    <div class="mb-3">
        <label for="" class="form-label">Extra</label>
        <p-editor [(ngModel)]="isDataMedia.extra" [style]="{ height: '180px' }" data-value=""></p-editor>
    </div>


    <div class="d-flex justify-content-end">
        <p-button
                (click)="updateData(isDataMedia.id, isDataMedia.title, fileInputUpdate, isDataMedia.price, isDataMedia.position, isDataMedia.status, isDataMedia.introduce, isDataMedia.description, isDataMedia.extra)"
                label="Lưu" icon="pi pi-check"></p-button>
    </div>
</p-dialog>

<p-dialog header="Sửa sản phẩm" [(visible)]="isShowEditMediaDetails" [modal]="true"
          [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '80vw', height: '80vh' }"
          [draggable]="false" [resizable]="false">
    <div class="box-table table-responsive">


        <table class="table">
            <thead class="table-dark">
            <tr>
                <td>Hình ảnh</td>
                <td>Vị trí</td>
                <td>Trạng thái</td>
                <td>Thay ảnh</td>
                <td>Action</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of this.listItemDetals;index as i">
                <td>
                    <span class="d-block table-img view-edit-img">
                        <img src="{{item.url}}" alt="">
                    </span>
                </td>
                <td>
                    <input [(ngModel)]="item.position" type="number" min="1" class="form-control" id=""
                           value="{{item.position}}">
                </td>
                <td>
                    <div class="">

                        <div class="form-check form-check-inline">
                            <input class="form-check-input cursor-pointer" value="1"
                                   (change)="handleChangeDetails($event)"
                                   [checked]="item.status == 1" type="radio" name="inlineRadioOptions{{i+1}}"
                                   id="inlineRadio1">
                            <label class="form-check-label cursor-pointer" for="inlineRadio1">Hoạt động</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input cursor-pointer" value="0"
                                   (change)="handleChangeDetails($event)"
                                   [checked]="item.status == 0" type="radio" name="inlineRadioOptions{{i+1}}"
                                   id="inlineRadio2">
                            <label class="form-check-label cursor-pointer" for="inlineRadio2">Không hoạt dộng</label>
                        </div>
                    </div>
                </td>
                <td>
                    <input #fileInputUpdateDetails accept="image/*" (change)="uploadImageUpdateDetails($event)"
                           type="file" id="">
                </td>
                <td>
                    <p-button label="Lưu"
                              (click)="updateDataDetails(item, item.id, item.position, item.status, fileInputUpdateDetails)"
                              icon="pi pi-check"></p-button>
                </td>
            </tr>
            </tbody>
        </table>

    </div>

</p-dialog>
