import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {
    debounce
} from 'lodash';
import {environment} from '../../../environments/environment';
import {DeliveryService} from "../../_services/delivery.service";

@Component({
    selector: 'app-delivery',
    templateUrl: './delivery.component.html',
    styleUrls: ['./delivery.component.css']
})
export class DeliveryComponent implements OnInit {

    public breadcrumb: any;

    isShowModalAdd: boolean;
    code: string = ''
    name: string = ''
    note: string = ''
    price: string = ''
    listItem = [];
    p: any;

    isShowEdit: boolean;

    isData: any = {};
    selectedOption: any;


    constructor(private deliveryService: DeliveryService) {
    }

    ngOnInit() {
        this.breadcrumb = {
            'mainlabel': 'Phí vận chuyển',
            'links': [
                {
                    'name': 'Home',
                    'isLink': true,
                    'link': '/changelog'
                },
                {
                    'name': 'Phí vận chuyển',
                    'isLink': false,
                    'link': '#'
                },
            ]
        };

        this.load();
    }

    transform(value: number): string {
        if (isNaN(value)) return '';
        const roundedValue = Math.round(value);
        return roundedValue.toLocaleString('vi-VN', {style: 'currency', currency: 'VND'});
    }

    load() {
        this.deliveryService.getDelivery().subscribe(item => {
            console.log(item);
            item.data.forEach(item => {
                this.listItem.push(item);
                this.listItem = this.listItem.sort((a, b) => a.id - b.id);
            })

        });
    }

    showModalAdd() {
        this.isShowModalAdd = true;
    }

    addData() {
        console.log(this.price)
        if (this.name && this.name && this.price) {

            let code = this.code
            let name = this.name
            let note = this.note
            let price = this.price

            let data = {code, name, note, price}

            this.deliveryService.postData(data).subscribe(item => {

                Swal.fire({
                    title: 'Thêm thành công',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    this.name = ''
                    this.name = ''
                    this.price = ''
                    this.listItem = [];
                    this.load();
                    this.isShowModalAdd = false;
                });
            });


        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Lỗi !',
                text: 'Vui lòng nhập đủ thông tin phí vận chuyển',
                // footer: '<a href="#">Why do I have this issue?</a>'
            });
        }

    }

    removeImage(item) {
        let accesstoken = localStorage.getItem('accesstoken');
        Swal.fire({
            title: 'Bạn có muốn xoá không ?',
            // text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                let id = item.id;

                this.deliveryService.deleteData(id).subscribe(item => {

                });
                this.listItem = [];
                this.load();

                Swal.fire({
                    title: 'Đã xoá!',
                    // text: 'Your file has been deleted.',
                    icon: 'success'
                });
            }
        });
    }

    editImage(item) {
        this.isShowEdit = true;
        this.isData = item;
        this.selectedOption = item.status;
    }

    updateData(id, name, note, price) {

        let data = {id, name, note, price}
        console.log('data update ', data)

        this.deliveryService.updateData(data).subscribe(item => {
            Swal.fire({
                title: 'Sửa thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

                this.listItem = [];
                this.load();
                this.isShowEdit = false;

            });
        });


    }
}
