import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class DeliveryService {

    requestOptions: any;
    accesstoken: string;


    constructor(private _http: HttpClient) {
        this.accesstoken = localStorage.getItem('accesstoken');

    }

    getDelivery() {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            // 'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/delivery`

        let delivery = this._http
            .get(endpoint, requestOptions)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );


        return delivery;
    }

    // postData(datal): Observable<any> {
    //     const headerDict = {
    //         Authorization: `Bearer ${this.accesstoken}`,
    //         //'Content-Type': 'application/json',
    //     };
    //     const requestOptions = {
    //         headers: new HttpHeaders(headerDict),
    //     };
    //     const formData = new FormData();
    //     formData.append('myfile', myfile);
    //     formData.append('description', description);
    //     let result = this._http.post<any>(`${environment.URL}${url}/`, formData, requestOptions);
    //     return result;
    // }

    postData(data): Observable<any> {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let body = {
            "name": data.name,
            "note": data.note,
            "price": data.price,
        }

        let endpoint = `${environment.URL}/delivery`

        let result = this._http.post(endpoint, body).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'support page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));

        return result;
    }

    deleteData(id) {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/delivery/${id}`


        let result = this._http.delete(endpoint, requestOptions).pipe(map((res: any) => {
            return res;
        }));
        return result;
    }

    updateData(data) {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let body = {
            "name": data.name,
            "note": data.note,
            "price": data.price,
        }

        let endpoint = `${environment.URL}/delivery/${data.id}`;


        let result = this._http.put(endpoint, body, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }


}
