import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {PartnerService} from '../../_services/partner.service';
import {
    debounce
} from 'lodash';

@Component({
    selector: 'app-partner',
    templateUrl: './partner.component.html',
    styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {

    constructor(private partnerService: PartnerService) {

    }

    public breadcrumb: any;

    isShowModalAdd: boolean;
    isShowViewPartner: boolean;
    isShowEditPartner: boolean;

    isDataPartner: any = {};

    namePartner: string = '';
    nameImageThumbAdd: any;
    nameImageAdd1: any;
    nameImageAdd2: any;
    txtDescriptionAdd1: string = '';
    txtDescriptionAdd2: string = '';

    selectedOption: any;

    nameImageUpdateThumb: any;
    nameImageUpdate1: any;
    nameImageUpdate2: any;

    urlMediaThumbEdit: any;
    urlMediaThumbEdit1: any;
    urlMediaThumbEdit2: any;


    listItem = [];
    p: any;
    valueSearch: string = '';
    listTmp: any = [];
    filterArr: any = []

    ngOnInit() {
        this.breadcrumb = {
            'mainlabel': 'Đối tác',
            'links': [
                {
                    'name': 'Home',
                    'isLink': true,
                    'link': '/changelog'
                },
                {
                    'name': 'Đối tác',
                    'isLink': false,
                    'link': '#'
                },
            ]
        };

        this.load();
    }

    load() {
        let accesstoken = localStorage.getItem('accesstoken');


        this.partnerService.getPartner(accesstoken, '/partner').subscribe(item => {
            item.data.forEach(item => {
                item.created_at = this.convertToDdMmYyyy(item.created_at);
                item.updated_at = this.convertToDdMmYyyy(item.updated_at);
                this.listItem.push(item);
                this.listTmp = this.listItem;

            });
        });
    }

    search = debounce((event) => {

        let value = event.target.value;
        let key = event.keyCode || event.charCode;
        if (key == 8 || key == 46) {
            this.listItem = this.listTmp;
        }
        if (value !== '') {
            this.filterArr = this.listItem.filter(item => item.name.toLowerCase().includes(value.toLowerCase()));

            this.listItem = this.filterArr;
        } else {
            this.listItem = this.listTmp;
        }
    }, 500);

    filterStatus(event: any) {
        let value = event.target.value
        if (value == 'all') {
            this.listItem = this.listTmp
        } else if (value == '1') {
            this.listItem = this.listTmp
            this.filterArr = this.listItem.filter(item => item.status == 1);
            this.listItem = this.filterArr;
        } else if (value == '0') {
            this.listItem = this.listTmp
            this.filterArr = this.listItem.filter(item => item.status == 0);
            this.listItem = this.filterArr;
        }
    }

    convertToDdMmYyyy(timestamp) {
        const dateObject = new Date(timestamp);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
    }

    showModalAdd() {
        this.isShowModalAdd = true;
    }


    uploadImageAddThumb(event: Event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageThumbAdd = fileList[0];
        }
    }

    uploadImageAdd1(event: Event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageAdd1 = fileList[0];
        }
    }

    uploadImageAdd2(event: Event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageAdd2 = fileList[0];
        }
    }


    addData(name, fileInput, fileInput1, description1, fileInput2, description2) {
        let accesstoken = localStorage.getItem('accesstoken');


        if (name && this.nameImageThumbAdd && this.nameImageAdd1 && this.nameImageAdd2 && description1 && description2) {

            let tmpThumb = this.nameImageThumbAdd;
            let tmpImage1 = this.nameImageAdd1;
            let tmpImage2 = this.nameImageAdd2;

            let data = {name, tmpThumb, tmpImage1, description1, tmpImage2, description2};

            this.partnerService.postData(accesstoken, data, '/partner').subscribe(item => {

                Swal.fire({
                    title: 'Thêm thành công',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    fileInput.value = '';
                    fileInput1.value = '';
                    fileInput2.value = '';
                    this.namePartner = '';
                    this.txtDescriptionAdd1 = '';
                    this.txtDescriptionAdd2 = '';
                    this.listItem = [];
                    this.load();
                    this.isShowModalAdd = false;
                });
            });


        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Lỗi !',
                text: 'Vui lòng nhập đủ thông tin mô tả và hình ảnh',
                // footer: '<a href="#">Why do I have this issue?</a>'
            });
        }


    }

    handleChange(event) {
        // console.log(event.target)
        if (event.target.checked) {
            this.selectedOption = event.target.value;
            console.log(this.selectedOption);
        }

    }

    uploadImageUpdateThumb(event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageUpdateThumb = fileList[0];
        }
    }

    uploadImageUpdate1(event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageUpdate1 = fileList[0];
        }
    }

    uploadImageUpdate2(event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageUpdate2 = fileList[0];
        }
    }

    updateData(id, name, fileInputThumb, fileInput1, description1, fileInpu2, description2, position, status) {
        let accesstoken = localStorage.getItem('accesstoken');


        let tmpThumb = this.nameImageUpdateThumb;
        let tmpImage1 = this.nameImageUpdate1;
        let tmpImage2 = this.nameImageUpdate2;
        let tmpStatus = Number(this.selectedOption);

        let data = {id, name, tmpThumb, tmpImage1, description1, tmpImage2, description2, position, tmpStatus};

        this.partnerService.updateData(accesstoken, data, '/partner').subscribe(item => {
            Swal.fire({
                title: 'Sửa thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

                this.nameImageUpdateThumb = '';
                this.nameImageUpdate1 = '';
                this.nameImageUpdate2 = '';
                fileInputThumb.value = '';
                fileInput1.value = '';
                fileInpu2.value = '';
                this.listItem = [];
                this.load();
                this.isShowEditPartner = false;

            });
        });
    }

    viewPartner(url, url1, url2) {
        url = url.replace('Publics', '');
        url1 = url1.replace('Publics', '');
        url2 = url2.replace('Publics', '');
        this.urlMediaThumbEdit = url;
        this.urlMediaThumbEdit1 = url1;
        this.urlMediaThumbEdit2 = url2;

        this.isShowViewPartner = true;
    }

    editPartner(item) {
        this.isShowEditPartner = true;
        this.isDataPartner = item;
        this.selectedOption = item.status;
    }


    removePartner(item) {
        let accesstoken = localStorage.getItem('accesstoken');


        Swal.fire({
            title: 'Bạn có muốn xoá không ?',
            // text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                let id = item.id;
                console.log(id);

                this.partnerService.deleteData(accesstoken, id, '/partner').subscribe(item => {
                    console.log(item);
                });
                this.listItem = [];
                this.load();

                Swal.fire({
                    title: 'Đã xoá!',
                    // text: 'Your file has been deleted.',
                    icon: 'success'
                });
            }
        });
    }

}
