import {Routes, RouterModule} from '@angular/router';
import {PublicLayoutComponent} from './_layout/public-layout/public-layout.component';
import {PrivateLayoutComponent} from './_layout/private-layout/private-layout.component';
import {AuthGuard} from './_guards/auth.guard';
import {RegisterComponent} from './register';
import {LoginComponent} from './login';
import {ChangelogComponent} from './changelog/changelog.component';
import {TopBannerComponent} from "./sidebar/top-banner/top-banner.component";
import {MediaComponent} from "./sidebar/media/media.component";
import {PartnerComponent} from "./sidebar/partner/partner.component";
import {RecipesComponent} from "./sidebar/recipes/recipes.component";
import {DryPearlComponent} from "./sidebar/dry-pearl/dry-pearl.component";
import {FreshPearlComponent} from "./sidebar/fresh-pearl/fresh-pearl.component";
import {UserComponent} from "./sidebar/user/user.component";
import {NewsComponent} from "./sidebar/news/news.component";
import {PolicyComponent} from "./sidebar/policy/policy.component";
import {IntroduceComponent} from "./sidebar/introduce/introduce.component";
import {OrderComponent} from './sidebar/order/order.component';
import {DeliveryComponent} from "./sidebar/delivery/delivery.component";

const appRoutes: Routes = [
    // Public layout
    {
        path: '',
        component: PublicLayoutComponent,
        children: [
            {path: 'register', component: RegisterComponent},
            {path: 'login', component: LoginComponent},
            {path: '', component: LoginComponent}
        ]
    },
    // Private layout
    {
        path: '',
        component: PrivateLayoutComponent,
        children: [
            {path: 'logout', component: LoginComponent, canActivate: [AuthGuard]},
            {path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard]},
            {
                path: 'top-banner',
                component: TopBannerComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'media',
                component: MediaComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'partner',
                component: PartnerComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'recipes',
                component: RecipesComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'dry-pearl',
                component: DryPearlComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'fresh-pearl',
                component: FreshPearlComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'news',
                component: NewsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'user',
                component: UserComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'policy',
                component: PolicyComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'introduce',
                component: IntroduceComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'order',
                component: OrderComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'delivery',
                component: DeliveryComponent,
                canActivate: [AuthGuard]
            },
        ],
    },
    // otherwise redirect to home
    // {path: '**', redirectTo: 'changelog'},

];

export const routing = RouterModule.forRoot(appRoutes, {scrollOffset: [0, 0], scrollPositionRestoration: 'top'});
