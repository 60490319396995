import {AngularFireAuth} from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import Swal from 'sweetalert2';

// import * as CryptoJS from 'crypto-js';


@Injectable()
export class AuthService {

    requestOptions: any;

    constructor(public afAuth: AngularFireAuth,
                private _http: HttpClient) {
        this.requestOptions = {
            headers: new HttpHeaders({
                // 'Authorization': `Bearer ${this.accesstoken}`,
                'Content-Type': 'application/json'
            })
        }


    }


    login(username, password, url): Observable<any> {

        // let encryptedText = CryptoJS.AES.encrypt(password, this.secretKey.trim()).toString();
        // console.log(encryptedText)
        let payload = {
            'username': username,
            'password': password
        }

        console.log('payload ', payload)
        console.log('${environment.URL}${url} ', `${environment.URL}${url}`)
        // return
        let apilogin = this._http.post(`${environment.URL}${url}`, payload).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'Username or password is incorrect !!!',
                    text: 'Try again ?',
                    // footer: '<a href="">Forgot password ?</a>'
                })

                //   .then(function() {
                //     window.location.reload();
                // })
            );

        }));
        return apilogin;
        ;
    }

    // Facebook login
    doFacebookLogin() {
        return new Promise<any>((resolve, reject) => {
            const provider = new firebase.auth.FacebookAuthProvider();
            this.afAuth.signInWithPopup(provider).then(
                res => {
                    resolve(res);
                },
                err => {
                    console.log(err);
                    reject(err);
                }
            );
        });
    }

    // Github login
    doGitHubLogin() {
        return new Promise<any>((resolve, reject) => {
            const provider = new firebase.auth.GithubAuthProvider();
            this.afAuth.signInWithPopup(provider).then(
                res => {
                    resolve(res);
                },
                err => {
                    console.log(err);
                    reject(err);
                }
            );
        });
    }

    // Twitter login
    doTwitterLogin() {
        return new Promise<any>((resolve, reject) => {
            const provider = new firebase.auth.TwitterAuthProvider();
            this.afAuth.signInWithPopup(provider).then(
                res => {
                    resolve(res);
                },
                err => {
                    console.log(err);
                    reject(err);
                }
            );
        });
    }

    // Google login
    doGoogleLogin() {
        return new Promise<any>((resolve, reject) => {
            const provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            this.afAuth.signInWithPopup(provider).then(
                res => {
                    resolve(res);
                },
                err => {
                    console.log(err);
                    reject(err);
                }
            );
        });
    }

    // Register
    doRegister(value) {
        return new Promise<any>((resolve, reject) => {
            firebase
                .auth()
                .createUserWithEmailAndPassword(value.email, value.password)
                .then(
                    res => {
                        resolve(res);
                    },
                    err => reject(err)
                );
        });
    }

    // Login
    doLogin(value) {
        return new Promise<any>((resolve, reject) => {
            firebase
                .auth()
                .signInWithEmailAndPassword(value.email, value.password)
                .then(
                    res => {
                        resolve(res);
                    },
                    err => reject(err)
                );
        });
    }

    // Logout
    doLogout() {
        return new Promise<void>((resolve, reject) => {
            // if (firebase.auth().currentUser) {
            //     localStorage.removeItem('accesstoken');
            //     localStorage.removeItem('remember');
            //     this.afAuth.signOut();
            //     resolve();
            // } else {
            //     localStorage.removeItem('accesstoken');
            //     resolve();
            // }
            localStorage.removeItem('accesstoken');
            resolve();
        });
    }
}
