<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <section id="drag-area">
                <div class='main-table-containter'>
                    <div class="d-flex justify-content-between mb-1 box-head">


                        <div class="d-flex box-filter align-items-center" style="gap: 10px">

                            <label class="mb-0">Lọc theo trạng thái</label>
                            <ng-select [items]="singleSelectArray" placeholder="Chọn trạng thái" bindLabel="item_text"
                                       class="status-select"
                                       [(ngModel)]="selectedItem" [clearable]="false"
                                       (change)="onChange()">
                            </ng-select>
                        </div>


                    </div>
                    <div class="box-table table-responsive">
                        <table class="table">
                            <thead class="table-dark">
                            <tr>
                                <td>STT</td>
                                <td>Mã đặt hàng</td>
                                <td>Thông tin khách hàng</td>
                                <td>Tổng đơn hàng</td>
                                <td>Ngày đặt hàng</td>
                                <td>Địa chỉ giao hàng</td>
                                <td>Hình thức thanh toán</td>
                                <td>Trạng thái</td>
                                <td>Hành động</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                    *ngFor="let item of orders| paginate: { itemsPerPage: 5, currentPage: p }; index as i">
                                <td>{{ i + 1 }}</td>
                                <td>{{ item.orderCode }}</td>
                                <td>
                                    <p>{{ item.customerAddress }}</p>
                                    <p>{{ item.customerName }}</p>
                                    <p>{{ item.customerPhone }}</p>
                                    <p>{{ item.customerEmail }}</p>
                                </td>
                                <td>{{ transform(item.totalAmount) }}</td>
                                <td>{{ formatDate(item.orderDate) }}</td>
                                <td>{{ item.shippingAddress }}</td>
                                <td>{{ formatPaymentMethod(item.paymentCode) }}</td>
                                <td>{{ formatStatus(item.orderStatus) }}</td>
                                <td>

                                    <div class="row justify-content-center" style="gap: 10px">
                                        <div class="box">
                                            <p-button class="btn-view" icon="pi pi-eye" (click)="detail(item)"
                                                      label="Chi tiết"></p-button>
                                        </div>
                                        <div class="box">
                                            <p-button icon="pi pi-cog"
                                                      (click)="updateStatus(item)"
                                                      label="Cập nhật"></p-button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="pagination" (pageChange)="p = $event"></pagination-controls>
                    </div>
                </div>

            </section>
        </div>
    </div>
</div>

<p-dialog header="Chi tiết đơn hàng" [(visible)]="isDetail" [modal]="true" [breakpoints]="{ '750px': '90vw' }"
          [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false" (onHide)="resetDetail()">
    <div class="d-flex align-items-center justify-content-between">
        <p><strong>Mã đơn hàng:</strong> {{ orderArray.orderCode }}</p>
        <p><strong>Ngày đặt hàng:</strong> {{ orderArray.orderDate }}</p>
    </div>
    <div class="box-table table-responsive">
        <table class="table">
            <thead class="table-dark">
            <tr>
                <td>Tên sản phẩm</td>
                <td>Số lượng</td>
                <td>Đơn giá</td>
                <td>Thành tiền</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of orderArray.details">
                <td>{{ item.productName }}</td>
                <td>{{ Number(item.quantity) }}</td>
                <td>{{ transform(Number(item.unitPrice)) }}</td>
                <td>{{ transform(Number(item.quantity) * Number(item.unitPrice)) }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</p-dialog>

<p-dialog header="Cập nhật trạng thái" [(visible)]="isUpdate" [modal]="true" [breakpoints]="{ '750px': '90vw' }"
          [style]="{ width: '35vw' }" [draggable]="false" [resizable]="false" (onHide)="resetUpdateStatus()">
    <div *ngIf="selectedUpdateItem" class="d-flex align-items-center justify-content-between">
        <p><strong>Mã đơn hàng:</strong> {{ selectedUpdateItem.orderCode }}</p>
    </div>
    <div class="radio-container">

        <div class="radio-wrapper" *ngFor="let cate of categories">
            <label class="radio-button">
                <input id="{{cate.item_id}}" name="radio-group" type="radio" [checked]="cate.item_id == this.checkStatus" (change)="changeStatus($event)">
                <span class="radio-checkmark"></span>
                <span class="radio-label">{{cate.item_text}}</span>
            </label>
        </div>


    </div>
    <div class="d-flex justify-content-end">
        <p-button (click)="updateData()" label="Lưu"
                  icon="pi pi-check"></p-button>
    </div>
</p-dialog>
