<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <section id="drag-area">

                <div class='main-table-containter'>

                    <div class="flex justify-content-center mb-1">
                        <p-button (click)="showModalAdd()" icon="pi pi-plus" label="Thêm"></p-button>
                        <p-dialog header="Thêm media" [(visible)]="isShowModalAdd" [modal]="true"
                                  [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw' }"
                                  [draggable]="false" [resizable]="false">
                            <div class="mb-2">
                                <label for="" class="form-label">Tiêu đề</label>
                                <input [(ngModel)]="txtTitleAdd" type="text" class="form-control" id="">
                            </div>
                            <div class="mb-2">
                                <label for="" class="form-label">Mô tả</label>
                                <p-editor [(ngModel)]="txtDescriptionAdd" [style]="{ height: '180px' }"></p-editor>
                            </div>
                            <div class="mb-2">
                                <label for="" class="form-label">Link media</label>
                                <input [(ngModel)]="txtLinkAdd" type="text" class="form-control" id="">
                            </div>
                            <div class="mb-3">
                                <p>Chọn ảnh</p>
                                <input #fileInputAdd accept="image/*" (change)="uploadImageAdd($event)" type="file" id="formFile">
                            </div>

                            <div class="d-flex justify-content-end">
                                <p-button (click)="addData(txtTitleAdd, txtDescriptionAdd, txtLinkAdd, fileInputAdd)" label="Lưu"
                                          icon="pi pi-check"></p-button>
                            </div>
                        </p-dialog>
                    </div>

                    <div class="box-table table-responsive">


                        <table class="table">
                            <thead class="table-dark">
                            <tr>
                                <td>#</td>
                                <td>Tiêu đề</td>
                                <td>Mô tả</td>
                                <td>Vị trí</td>
                                <td>Trạng thái</td>
                                <td>Link youtube</td>
                                <td>Ngày cập nhật</td>
                                <td>Action</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of listItem">
                                <td [innerHTML]="item.id"></td>
                                <td [innerHTML]="item.title"></td>
                                <td class="txt-line" [innerHTML]="item.description"></td>
                                <td [innerHTML]="item.position"></td>
                                <td>
                                    <span *ngIf="item.status == 1; else elseBlock">Hoạt động</span>
                                    <ng-template #elseBlock>
                                        <span>Không hoạt động</span>
                                    </ng-template>
                                </td>
                                <td class="txt-line">
                                    <a target="_blank" href="{{item.url_media}}">Xem đường dẫn</a>
                                </td>
                                <td>
                                    <p>Ngày tạo: {{ item.created_at }}</p>
                                    <p class="mb-0">Ngày cập nhật: {{ item.updated_at }}</p>
                                </td>
                                <td>

                                    <div class="row justify-content-center" style="gap: 10px">
                                        <div class="box">
                                            <p-button class="btn-view" icon="pi pi-eye"  (click)="viewMedia(item.url_thum)" label="Xem"></p-button>
                                            <p-dialog header="Hình ảnh" [(visible)]="isShowViewMedia" [modal]="true"
                                                      [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw' }"
                                                      [draggable]="false" [resizable]="false">
                                                <p class="text-center">
                                                    <img src="{{urlImageEdit}}" alt="">
                                                </p>
                                            </p-dialog>
                                        </div>

                                        <div class="box">
                                            <p-button (click)="editMedia(item)"  icon="pi pi-cog" label="Sửa"></p-button>

                                        </div>

                                        <div class="box">
                                            <p-button class="btn-remove" icon="pi pi-trash"  (click)="removeMedia(item)" label="Xoá"></p-button>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>

                </div>

            </section>
        </div>
    </div>
</div>

<p-dialog header="Sửa media" [(visible)]="isShowEditMedia" [modal]="true"
          [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw' }"
          [draggable]="false" [resizable]="false">
    <div class="mb-2">
        <label for="" class="form-label">Tiêu đề</label>
        <input [(ngModel)]="isDataMedia.title" type="text" class="form-control" id="" value="{{isDataMedia.title}}">
    </div>
    <div class="mb-2">
        <label for="" class="form-label">Mô tả</label>
        <p-editor [(ngModel)]="isDataMedia.description" [style]="{ height: '180px' }" data-value=""></p-editor>
    </div>
    <div class="mb-2">
        <label for="" class="form-label">Link media</label>
        <input [(ngModel)]="isDataMedia.url_media" type="text" class="form-control" id="" value="{{isDataMedia.url_media}}">
    </div>
    <div class="mb-2">
        <label for="txtarea3" class="form-label">Vị trí</label>
        <input type="number" class="form-control" id="txtarea3" [(ngModel)]="isDataMedia.position" value="{{isDataMedia.position}}">
    </div>

    <div class="mb-2">
        <p class="form-label">Trạng thái</p>

        <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" value="1" (change)="handleChange($event)" [checked]="selectedOption == 1" type="radio" name="inlineRadioOptions" id="inlineRadio1" >
            <label class="form-check-label cursor-pointer" for="inlineRadio1">Hoạt động</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" value="0" (change)="handleChange($event)" [checked]="selectedOption == 0" type="radio" name="inlineRadioOptions" id="inlineRadio2" >
            <label class="form-check-label cursor-pointer" for="inlineRadio2">Không hoạt động</label>
        </div>


    </div>

    <div class="mb-2">
        <p>Chọn ảnh</p>
        <input #fileInputUpdate accept="image/*" (change)="uploadImageUpdate($event)" type="file" id="">

    </div>


    <div class="d-flex justify-content-end">
        <p-button (click)="updateData(isDataMedia.id, isDataMedia.title, isDataMedia.description, isDataMedia.url_media, isDataMedia.position, isDataMedia.status, fileInputUpdate)" label="Lưu" icon="pi pi-check"></p-button>
    </div>
</p-dialog>


