import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class DryPearlService {

    requestOptions: any;


    constructor(private _http: HttpClient) {
        // this.requestOptions = {
        //     headers: new HttpHeaders({
        //         // 'Authorization': `Bearer ${this.accesstoken}`,
        //         'Content-Type': 'application/json'
        //     })
        // };
    }


    setConfigPrice(body,id) {
        let accesstoken = localStorage.getItem('accesstoken');

        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        let endpoint = `${environment.URL}/driedmilktea/showprice/${id}`;
        let result = this._http.put(endpoint, body, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }


    getDryPearl(accesstoken, url) {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        let endpoint = `${environment.URL}/driedmilktea`;
        console.log(endpoint);
        let dryPearlResult = this._http
            .get(endpoint, requestOptions)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );


        return dryPearlResult;
    }

    getConfigPrice() {
        let accesstoken = localStorage.getItem('accesstoken');

        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        let endpoint = `${environment.URL}/driedmilktea/showprice`;
        let dryPearlResult = this._http
            .get(endpoint, requestOptions)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );


        return dryPearlResult;
    }

    postData(accesstoken, data, url): Observable<any> {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            //'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('driedmilktea_thum', data.imageThumb);
        // formData.append('driedmilktea_detail', data.imageMain);
        // Loop through each imageMain and append it to formData
        if (data.imageMain instanceof Array) {
            for (let i = 0; i < data.imageMain.length; i++) {
                formData.append('driedmilktea_detail', data.imageMain[i]);
            }
        } else {
            formData.append('driedmilktea_detail', data.imageMain);
        }
        formData.append('price', data.price);
        formData.append('description', data.description);
        formData.append('introduce', data.introduce);
        formData.append('extra', data.extra);

        let result = this._http.post<any>(`${environment.URL}${url}/`, formData, requestOptions);

        return result;
    }

    deleteData(accesstoken, id, url) {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        let result = this._http.delete(`${environment.URL}${url}/${id}`, requestOptions).pipe(map((res: any) => {
            return res;
        }));
        return result;
    }

    updateData(accesstoken, data, url) {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            //'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        const formData = new FormData();
        formData.append('title', data.title);
        formData.append('driedmilktea_thum', data.tmpImage);

        if (data.tmpImageDetails instanceof Array) {
            for (let i = 0; i < data.tmpImageDetails.length; i++) {
                formData.append('driedmilktea_detail', data.tmpImageDetails[i]);
            }
        } else {
            formData.append('driedmilktea_detail', data.tmpImageDetails);
        }

        formData.append('price', data.price);
        formData.append('position', data.position);
        // @ts-ignore
        formData.append('status', Number(data.tmpStatus));
        formData.append('introduce', data.introduce);
        formData.append('description', data.description);
        formData.append('extra', data.extra);


        let result = this._http.put(`${environment.URL}${url}/${data.id}`, formData, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }

    updateDataDetails(accesstoken, data, url) {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            //'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        ;
        const formData = new FormData();
        formData.append('myfile', data.tmpImage);
        formData.append('position', data.position);
        formData.append('status', data.tmpStatus);

        let result = this._http.put(`${environment.URL}${url}/${data.id}`, formData, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }

}
