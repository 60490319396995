import { AfterViewInit, Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FreshPearlService } from '../../_services/fresh-pearl.service';
// import {DryPearlService} from '../../_services/dry-pearl.service';
import {
    debounce
} from 'lodash';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-fresh-pearl',
    templateUrl: './fresh-pearl.component.html',
    styleUrls: ['./fresh-pearl.component.css']
})
export class FreshPearlComponent implements OnInit, AfterViewInit {

    constructor(private freshPearlService: FreshPearlService) {

    }
    isShowPrice: boolean = false;

    public breadcrumb: any;

    isShowModalAdd: boolean;
    isShowViewMedia: boolean;
    isShowEditMedia: boolean;
    isShowEditMediaDetails: boolean;

    isDataMedia: any = {};
    isDataMediaDetails: any = {};

    txtTitleAdd: string = '';
    txtDescriptionAdd: string = '';
    nameImageAdd: any;
    nameImageAdd1: any;
    nameImageAdd2: any;
    nameImageAdd3: any;
    txtPriceAdd: string = '';
    txtIntroduceAdd: string = '';
    txtExtraAdd: string = '';
    urlMediaThumbEdit: string = '';
    urlMediaDetailEdit: string = '';
    listUrlMediaDetailEdit: any = [];

    nameImageUpdate: any;
    nameImageUpdate1: any;
    nameImageUpdate2: any;
    nameImageUpdate3: any;
    statusImageUpdate: any;
    nameImageUpdateDetails: any;

    selectedOption: any;
    selectedOptionDetails: any;


    listItem = [];
    listItemDetals = [];
    p: any;
    valueSearch: string = '';
    listTmp: any = [];
    filterArr: any = []
    urlThumView: string;
    idConfig : string;

    onCheckboxChange(event: any): void {
        let txt = "";
        let status = 0;
        if(!event.target.checked)
        {
            txt = "Xác nhận không hiển thị giá sản phẩm ?"
            status = 0;
        }
        else
        {
            txt = "Xác nhận hiển thị giá sản phẩm ?"
            status = 1;

        }
        Swal.fire({
            title: 'Xác nhận',
            text: txt,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                let body = {
                    status
                }
                console.log(body);

                this.freshPearlService.setConfigPrice(body,this.idConfig).subscribe(item => {
                    console.log(item);
                    Swal.fire({
                        title: 'Đã cập nhật!',
                        // text: 'Your file has been deleted.',
                        icon: 'success'
                    });
                    this.loadConfigPrice();
                });
            }
        });

    }




    ngOnInit() {
        this.breadcrumb = {
            'mainlabel': 'Trân châu tươi',
            'links': [
                {
                    'name': 'Home',
                    'isLink': true,
                    'link': '/changelog'
                },
                {
                    'name': 'Trân châu tươi',
                    'isLink': false,
                    'link': '#'
                },
            ]
        };

        this.load();
        this.loadConfigPrice();

    }

    ngAfterViewInit() {

    }

    loadConfigPrice()
    {

        this.freshPearlService.getConfigPrice().subscribe(item => {
            console.log(item);
            console.log()
            this.isShowPrice = Number(item.data[0]["showPriceFreshPearl"]) === 0 ? false : true;
            console.log(item);
            this.idConfig = item.data[0].id;
            

        });
    }

    load() {
        let accesstoken = localStorage.getItem('accesstoken');


        this.freshPearlService.getFreshPearl(accesstoken, `/freshmilktea`).subscribe(item => {
            item.data.forEach(item => {
                item.created_at = this.convertToDdMmYyyy(item.created_at);
                item.updated_at = this.convertToDdMmYyyy(item.updated_at);
                this.listItem.push(item);
                this.listTmp = this.listItem;

            });
        });
    }

    search = debounce((event) => {

        let value = event.target.value;
        let key = event.keyCode || event.charCode;
        if (key == 8 || key == 46) {
            this.listItem = this.listTmp;
        }
        if (value !== '') {
            this.filterArr = this.listItem.filter(item => item.title.toLowerCase().includes(value.toLowerCase()));

            this.listItem = this.filterArr;
        } else {
            this.listItem = this.listTmp;
        }
    }, 500);

    filterStatus(event: any) {
        let value = event.target.value
        if (value == 'all') {
            this.listItem = this.listTmp
        } else if (value == '1') {
            this.listItem = this.listTmp
            this.filterArr = this.listItem.filter(item => item.status == 1);
            this.listItem = this.filterArr;
        } else if (value == '0') {
            this.listItem = this.listTmp
            this.filterArr = this.listItem.filter(item => item.status == 0);
            this.listItem = this.filterArr;
        }
    }

    convertToDdMmYyyy(timestamp) {
        const dateObject = new Date(timestamp);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
    }

    //add

    uploadImageAdd(event: Event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageAdd = fileList[0];
        }
    }

    uploadImageAdd1(event: Event) {
        const element = event.currentTarget as HTMLInputElement;

        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageAdd1 = fileList[0];
        }
    }

    uploadImageAdd2(event: Event) {
        const element = event.currentTarget as HTMLInputElement;

        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageAdd2 = fileList[0];
        }
    }

    uploadImageAdd3(event: Event) {
        const element = event.currentTarget as HTMLInputElement;

        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageAdd3 = fileList[0];
        }
    }


    addData(title, fileInputAdd, fileInputAdd1, fileInputAdd2, fileInputAdd3, price, introduce, description, extra) {
        let accesstoken = localStorage.getItem('accesstoken');


        if (title && price && introduce && description && extra && this.nameImageAdd && (this.nameImageAdd1 || this.nameImageAdd2 || this.nameImageAdd3)) {

            let imageThumb = this.nameImageAdd;
            let tmpImg1 = this.nameImageAdd1;
            let tmpImg2 = this.nameImageAdd2;
            let tmpImg3 = this.nameImageAdd3;
            let imageMain = [tmpImg1, tmpImg2, tmpImg3];
            // let imageMain = tmpImg1;

            console.log('imageMain ', imageMain);
            // let imageMain2 = this.nameImageAdd1;
            // let imageMain3 = this.nameImageAdd1;

            let data = { title, imageThumb, imageMain, price, introduce, description, extra };

            this.freshPearlService.postData(accesstoken, data, '/freshmilktea').subscribe(item => {
                console.log(item);
                Swal.fire({
                    title: 'Thêm thành công',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    fileInputAdd.value = '';
                    fileInputAdd1.value = '';
                    fileInputAdd2.value = '';
                    fileInputAdd3.value = '';
                    this.txtTitleAdd = '';
                    this.txtPriceAdd = '';
                    this.txtIntroduceAdd = '';
                    this.txtExtraAdd = '';
                    this.txtDescriptionAdd = '';
                    this.listItem = [];
                    this.load();
                    this.isShowModalAdd = false;
                });
            });


        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Vui lòng nhập đủ thông tin mô tả và hình ảnh',
                // footer: '<a href="#">Why do I have this issue?</a>'
            });
        }

    }

    showModalAdd() {
        this.isShowModalAdd = true;
    }

    viewMedia(url_thumb, url) {
        url_thumb = url_thumb.replace('Publics/', '');
        this.urlThumView = environment.URL + '/' + url_thumb;
        this.urlMediaThumbEdit = url_thumb;
        console.log(url);
        this.listUrlMediaDetailEdit = [];
        url.forEach(item => {
            let sub = {};
            sub["url"] = environment.URL + '/' + item.url.replace('Publics/', '');
            this.listUrlMediaDetailEdit.push(sub);
        });
        this.isShowViewMedia = true;
    }

    //update

    uploadImageUpdate(event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageUpdate = fileList[0];
        }
    }

    uploadImageUpdate1(event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageUpdate1 = fileList[0];
        }
    }

    uploadImageUpdate2(event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageUpdate2 = fileList[0];
        }
    }

    uploadImageUpdate3(event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageUpdate3 = fileList[0];
        }
    }

    handleChange(event) {
        // console.log(event.target)
        if (event.target.checked) {
            this.selectedOption = event.target.value;
            console.log(this.selectedOption);
        }

    }


    editMedia(item) {
        // console.log(item);
        this.isShowEditMedia = true;
        this.isDataMedia = item;
        this.selectedOption = item.status;

    }


    updateData(id, title, fileInput, price, position, status, introduce, description, extra) {
        let accesstoken = localStorage.getItem('accesstoken');


        let tmpImage = this.nameImageUpdate;
        let tmpImage1 = this.nameImageUpdate1;
        let tmpImage2 = this.nameImageUpdate2;
        let tmpImage3 = this.nameImageUpdate3;
        let tmpStatus = Number(this.selectedOption);

        // let tmpImageDetails = [tmpImage1, tmpImage2, tmpImage3];


        let data = { id, title, tmpImage, price, position, tmpStatus, introduce, description, extra };

        this.freshPearlService.updateData(accesstoken, data, '/freshmilktea').subscribe(item => {

            Swal.fire({
                title: 'Sửa thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

                this.nameImageUpdate = '';
                fileInput.value = '';
                this.listItem = [];
                this.load();
                this.isShowEditMedia = false;

            });
        });

        // this.isShowEditMedia = false
    }

    uploadImageUpdateDetails(event) {
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        if (fileList) {
            this.nameImageUpdateDetails = fileList[0];
        }
    }


    editMediaDetails(item) {
        this.refresh();
        this.isShowEditMediaDetails = true;
        this.isDataMediaDetails = item;
        item.detail_images.forEach(item => {
            let dataSub = {}
            dataSub["url"] = environment.URL + '/' + item.url.replace('Publics/', '');
            dataSub["position"] = item.position;
            dataSub["status"] = Number(item.status);
            dataSub["id"] = item.id;


            this.listItemDetals.push(dataSub);
        });
        this.selectedOptionDetails = item.status;

    }

    handleChangeDetails(event) {
        // console.log(event.target)
        if (event.target.checked) {
            this.selectedOptionDetails = event.target.value;
            console.log(this.selectedOptionDetails);
        }

    }

    updateDataDetails(itemUpdate, id, position, status, fileInputUpdateDetails) {

        let accesstoken = localStorage.getItem('accesstoken');


        let tmpImage = this.nameImageUpdateDetails;
        // let tmpStatus = this.selectedOptionDetails;
        let tmpStatus = Number(this.selectedOptionDetails)

        let data = { id, tmpImage, position, tmpStatus };

        this.freshPearlService.updateDataDetails(accesstoken, data, '/freshmilktea/detail').subscribe(item => {

            console.log('item ', item);
            console.log('this.listItemDetals ', this.listItemDetals);
            Swal.fire({
                title: 'Sửa thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

                for (let i = 0; i < this.listItemDetals.length; i++) {
                    if (this.listItemDetals[i].id === itemUpdate.id) {
                        this.listItemDetals.splice(i, 1); // Xóa phần tử tại vị trí i
                        break; // Sau khi xóa phần tử, thoát khỏi vòng lặp
                    }
                }

                this.listItemDetals.push(itemUpdate);
                this.refresh();
                this.isShowEditMediaDetails = false;

            });
        });
    }

    refresh() {
        this.listItem = [];
        this.listItemDetals = [];
        this.load();
    }


    removeMedia(item) {
        let accesstoken = localStorage.getItem('accesstoken');


        Swal.fire({
            title: 'Bạn có muốn xoá không ?',
            // text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                let id = item.id;
                console.log(id);

                this.freshPearlService.deleteData(accesstoken, id, '/freshmilktea').subscribe(item => {
                    console.log(item);
                });
                this.listItem = [];
                this.load();

                Swal.fire({
                    title: 'Đã xoá!',
                    // text: 'Your file has been deleted.',
                    icon: 'success'
                });
            }
        });
    }
}
