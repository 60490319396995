import {Component, ViewChild, OnInit, ElementRef} from '@angular/core';
import {NewsService} from "../../_services/news.service";
import {IntroduceService} from "../../_services/introduce.service";

import Swal from "sweetalert2";
import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller,
    EffectFade
} from 'swiper';
import {environment} from "../../../environments/environment";
import {FormGroup, FormBuilder, Validators, NgForm} from '@angular/forms';

SwiperCore.use([EffectFade,
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller]);

@Component({
    selector: 'app-introduce',
    templateUrl: './introduce.component.html',
    styleUrls: ['./introduce.component.css']
})
export class IntroduceComponent implements OnInit {

    constructor(private newService: NewsService, private introduceService : IntroduceService) {
    }

    @ViewChild('labelImport1', { static: true }) labelImport1: ElementRef;
    @ViewChild('labelImport2', { static: true }) labelImport2: ElementRef;
    @ViewChild('labelImport3', { static: true }) labelImport3: ElementRef;
    @ViewChild('labelImport4', { static: true }) labelImport4: ElementRef;
    @ViewChild('labelImport5', { static: true }) labelImport5: ElementRef;

    filesToUpload: { [key: number]: File[] } = {};


    public breadcrumb: any;
    content1:string = "";
    content2:string = "";
    content3:string = "";
    content4:string = "";
    content5:string = "";
    image1:string = "";
    image2:string = "";
    image3:string = "";
    image4:string = "";
    image5:string = "";

    selectedFile1: File | null = null;
    base64Image1: string | null = null;

    selectedFile2: File | null = null;
    base64Image2: string | null = null;

    selectedFile3: File | null = null;
    base64Image3: string | null = null;

    selectedFile4: File | null = null;
    base64Image4: string | null = null;

    selectedFile5: File | null = null;
    base64Image5: string | null = null;
    
    ngOnInit() {
        this.breadcrumb = {
            'mainlabel': 'Quản lý giới thiệu',
            'links': [
                {
                    'name': 'Home',
                    'isLink': true,
                    'link': '/changelog'
                },
                {
                    'name': 'Quản lý giới thiệu',
                    'isLink': false,
                    'link': '#'
                },
            ]
        };




        this.load();

    }

    load(){

        this.introduceService.getIntroduce().subscribe(item => {
            if(item.success == true)
            {
                this.content1 = item.data[0].content1;
                this.content2 = item.data[0].content2;
                this.content3 = item.data[0].content3;
                this.content4 = item.data[0].content4;
                this.content5 = item.data[0].content5;
                this.base64Image1 = item.data[0].media.img_content1[0].data;
                this.base64Image2 = item.data[0].media.img_content2[0].data;
                this.base64Image3 = item.data[0].media.img_content3[0].data;
                this.base64Image4 = item.data[0].media.img_content4[0].data;
                this.base64Image5 = item.data[0].media.img_content5[0].data;
            }

        });
    }



    save()
    {
        this.updateImage();

    }

    updateContent()
    {

    }

    updateImage()
    {

        let body = {
            content1:this.content1,
            content2:this.content2,
            content3:this.content3,
            content4:this.content4,
            content5:this.content5,
            image :  this.filesToUpload



        }
        this.introduceService.updateIntroduce(body).subscribe(item => {
            console.log(item.success);
            if(item.success)
            {
                Swal.fire({
                    title: 'Cập nhật thành công',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    this.load();
    
                });
            }

        });
    }


    onFileChange(files: FileList, type: number) {

        const fileArray = Array.from(files);

        this.filesToUpload[`image${type}`] = fileArray;

        if (type == 1) {
            this.labelImport1.nativeElement.innerText = Array.from(files)
                .map(f => f.name)
                .join(', ');
            // this.fileToUpload1 = files.item(0);

            if(files.length > 0)
            {
                this.selectedFile1 = files[0];
                if (this.selectedFile1) {
                  const reader = new FileReader();
                  reader.onload = (e: any) => {
                    this.base64Image1 = e.target.result.split(',')[1];
    
                    this.filesToUpload[`image${type}`] = e.target.result.split(',')[1];
                  };
                  reader.readAsDataURL(this.selectedFile1);
                }
            }
            else
            {
                this.base64Image1 = "";
    
                this.filesToUpload[`image${type}`] = "";
            }



        }
        if (type == 2) {
            this.labelImport2.nativeElement.innerText = Array.from(files)
                .map(f => f.name)
                .join(', ');
            // this.fileToUpload2 = files.item(0);

            if(files.length > 0)
            {
                this.selectedFile2 = files[0];
                if (this.selectedFile2) {
                  const reader = new FileReader();
                  reader.onload = (e: any) => {
                    this.base64Image2 = e.target.result.split(',')[1];
                    this.filesToUpload[`image${type}`] = e.target.result.split(',')[1];
    
                  };
                  reader.readAsDataURL(this.selectedFile2);
                }
            }
            else
            {
                this.base64Image2 = "";
                this.filesToUpload[`image${type}`] = null;
            }


        }
        if (type == 3) {
            this.labelImport3.nativeElement.innerText = Array.from(files)
                .map(f => f.name)
                .join(', ');
            // this.fileToUpload3 = files.item(0);

            if(files.length > 0)
            {
                this.selectedFile3 = files[0];
                if (this.selectedFile3) {
                  const reader = new FileReader();
                  reader.onload = (e: any) => {
                    this.base64Image3 = e.target.result.split(',')[1];
                    this.filesToUpload[`image${type}`] = e.target.result.split(',')[1];
    
                  };
                  reader.readAsDataURL(this.selectedFile3);
                }
            }
            else
            {
                this.base64Image3 = ""
                this.filesToUpload[`image${type}`] = null;

            }


        }
        if (type == 4) {
            this.labelImport4.nativeElement.innerText = Array.from(files)
                .map(f => f.name)
                .join(', ');
            // this.fileToUpload4 = files.item(0);

            if(files.length > 0)
            {
                this.selectedFile4 = files[0];
                if (this.selectedFile4) {
                  const reader = new FileReader();
                  reader.onload = (e: any) => {
                    this.base64Image4 = e.target.result.split(',')[1];
                    this.filesToUpload[`image${type}`] = e.target.result.split(',')[1];
    
                  };
                  reader.readAsDataURL(this.selectedFile4);
                }

                
            }
            else
            {
                this.base64Image4 = ""
                this.filesToUpload[`image${type}`] = null;
            }



        }        
        if (type == 5) {
            this.labelImport5.nativeElement.innerText = Array.from(files)
                .map(f => f.name)
                .join(', ');
            // this.fileToUpload4 = files.item(0);

            if(files.length > 0)
            {
                this.selectedFile5 = files[0];
                if (this.selectedFile5) {
                  const reader = new FileReader();
                  reader.onload = (e: any) => {
                    this.base64Image5 = e.target.result.split(',')[1];
                    this.filesToUpload[`image${type}`] = e.target.result.split(',')[1];
    
                  };
                  reader.readAsDataURL(this.selectedFile5);
                }

            }
            else
            {
                this.base64Image5 = "";
                this.filesToUpload[`image${type}`] = null;
            }




        }

        // console.log(this.filesToUpload);
        



    }




}
