import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class RecipesService {

    accesstoken: string;

    constructor(private _http: HttpClient) {
        this.accesstoken = localStorage.getItem('accesstoken');
    }

    updateData(formData, id) {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            // 'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/recipes/${id}`;

        console.log('endpoint ', endpoint)

        let result = this._http.put(endpoint, formData, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }

    getRecipes() {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            // 'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/recipes`

        let newsResult = this._http
            .get(endpoint, requestOptions)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );


        return newsResult;
    }


    postData(data): Observable<any> {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            // 'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/recipes`

        let result = this._http.post<any>(endpoint, data, requestOptions);
        return result;
    }

    deleteData(id) {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/recipes/${id}`;


        let result = this._http.delete(endpoint, requestOptions).pipe(map((res: any) => {
            return res;
        }));
        return result;
    }

    deleteImageLeft(id) {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/recipes/imageleft/${id}`;
        console.log(endpoint);


        let result = this._http.delete(endpoint, requestOptions).pipe(map((res: any) => {
            return res;
        }));
        return result;
    }

    updateImageLeft(formData, id) {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            // 'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/recipes/imageleft/${id}`;


        let result = this._http.put(endpoint, formData, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }

    addImageLeft(formData, id) {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            // 'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/recipes/imageleft/${id}`;


        let result = this._http.post(endpoint, formData, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }

    updateUrlVideo(body,id) {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/recipes/video/${id}`;


        let result = this._http.put(endpoint, body, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }



}
