// Default menu settings configurations

export interface MenuItem {
    title: string;
    icon: string;
    page: string;
    isExternalLink?: boolean;
    issupportExternalLink?: boolean;
    badge: { type: string, value: string };
    submenu: {
        items: Partial<MenuItem>[];
    };
    section: string;
}

export interface MenuConfig {
    horizontal_menu: {
        items: Partial<MenuItem>[]
    };
    vertical_menu: {
        items: Partial<MenuItem>[]
    };
}

export const MenuSettingsConfig: MenuConfig = {
    horizontal_menu: {
        items: [
            // {
            //   title: 'Changelog',
            //   icon: 'la-file',
            //   page: '/changelog',
            //   badge: { type: 'badge-danger', value: '3.0' }
            // },
            // {
            //   title: 'Templates',
            //   icon: 'la-television',
            //   page: 'null',
            //   submenu: {
            //     items: [
            //       {
            //         title: 'Horizontal',
            //         page: 'null'
            //       },
            //       {
            //         title: 'Vertical',
            //         page: 'null'
            //       },
            //     ]
            //   }
            // },
            // {
            //   title: 'Raise Support',
            //   icon: 'la-support',
            //   page: 'https://pixinvent.ticksy.com/',
            //   isExternalLink: true
            // },
            // {
            //   title: 'Documentaion',
            //   icon: 'la-text-height',
            //   page: 'https://modern-admin-docs.web.app/html/ltr/documentation/index.html',
            //   isExternalLink: true,
            // },
            {
                title: 'Quản lý top banner',
                icon: 'la-text-height',
                page: 'top-banner',
            },
            {
                title: 'Media',
                icon: 'la-text-height',
                page: 'media',
            },
            {
                title: 'Đối tác',
                icon: 'la-text-height',
                page: 'partner',
            },
            {
                title: 'Công thức',
                icon: 'la-text-height',
                page: 'recipes',
            },
            {
                title: 'Trân châu khô',
                icon: 'la-text-height',
                page: 'dry-pearl',
            },
            {
                title: 'Trân châu tươi',
                icon: 'la-text-height',
                page: 'fresh-pearl',
            },
            {
                title: 'Quản lý tin tức',
                icon: 'la-text-height',
                page: 'news',
            },
            {
                title: 'Quản lý người dùng',
                icon: 'la-text-height',
                page: 'user',
            },
            {
                title: 'Quản lý chính sách',
                icon: 'la-text-height',
                page: 'policy',
            },
            {
                title: 'Quản lý giới thiệu',
                icon: 'la-text-height',
                page: 'introduce',
            }
        ]
    },
    vertical_menu: {
        items: [
            // {
            //   title: 'Changelog',
            //   icon: 'la-file',
            //   page: '/changelog',
            //   badge: { type: 'badge-danger', value: '3.0' }
            // },
            // {
            //   title: 'Templates',
            //   icon: 'la-television',
            //   page: 'null',
            //   submenu: {
            //     items: [
            //       {
            //         title: 'Horizontal',
            //         page: 'null'
            //       },
            //       {
            //         title: 'Vertical',
            //         page: 'null'
            //       },
            //     ]
            //   }
            // },
            // { section: 'SUPPORT', icon: 'la-ellipsis-h' },
            // {
            //   title: 'Raise Support',
            //   icon: 'la-support',
            //   page: 'https://pixinvent.ticksy.com/',
            //   isExternalLink: true
            // },
            // {
            //   title: 'Documentaion',
            //   icon: 'la-text-height',
            //   page: 'https://modern-admin-docs.web.app/html/ltr/documentation/index.html',
            //   isExternalLink: true,
            // },
            {
                title: 'Quản lý Top banner',
                icon: 'la-text-height',
                page: 'top-banner',
            },
            {
                title: 'Quản lý Media',
                icon: 'la-text-height',
                page: 'media',
            },
            {
                title: 'Quản lý Đối tác',
                icon: 'la-text-height',
                page: 'partner',
            },
            {
                title: 'Quản lý Công thức',
                icon: 'la-text-height',
                page: 'recipes',
            },
            {
                title: 'Quản lý Trân châu khô',
                icon: 'la-text-height',
                page: 'dry-pearl',
            },
            {
                title: 'Quản lý Trân châu tươi',
                icon: 'la-text-height',
                page: 'fresh-pearl',
            },
            {
                title: 'Quản lý Tin tức',
                icon: 'la-text-height',
                page: 'news',
            },
            // {
            //     title: 'Quản lý Người dùng',
            //     icon: 'la-text-height',
            //     page: 'user',
            // },
            {
                title: 'Quản lý Chính sách',
                icon: 'la-text-height',
                page: 'policy',
            },
            {
                title: 'Quản lý Giới thiệu',
                icon: 'la-text-height',
                page: 'introduce',
            },
            {
                title: 'Quản lý đặt hàng',
                icon: 'la-text-height',
                page: 'order',
            },
            {
                title: 'Quản lý phí vận chuyển',
                icon: 'la-text-height',
                page: 'delivery',
            }
        ]
    }

};





