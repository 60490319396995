<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <section id="drag-area">

                <div class='main-table-containter'>

                    <div class="d-flex justify-content-between mb-1 box-head">
                        <!-- <p-button (click)="showModalAdd()" icon="pi pi-plus" label="Thêm"></p-button> -->
                        <p-dialog header="Thêm chính sách" [(visible)]="isShowModalAdd" [modal]="true"
                                  [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw' }"
                                  [draggable]="false" [resizable]="false">
                            <div class="mb-2">
                                <p for="" class="form-label">Chọn chính sách</p>

                                <div class="select-policy">
                                    <select (change)="handleChange($event)">
                                        <option value="0" data-policy="Chính sách bảo mật">Chính sách bảo mật</option>
                                        <option value="1" data-policy="Chính sách bán hàng">Chính sách bán hàng</option>
                                        <option value="2" data-policy="Chính sách giao hàng">Chính sách giao hàng
                                        </option>
                                        <option value="3" data-policy="Chính sách kiểm hàng">Chính sách kiểm hàng
                                        </option>
                                        <option value="4" data-policy="Chính sách thanh toán">Chính sách thanh toán
                                        </option>
                                    </select>
                                </div>

                            </div>

                            <div class="mb-2">
                                <label for="" class="form-label">Nội dung</label>
                                <p class="input-pw">
                                    <p-editor [(ngModel)]="txtDescriptionAdd" [style]="{ height: '180px' }"></p-editor>
                                </p>
                                <!--                                <input [(ngModel)]="txtDescriptionAdd" type="text" class="form-control" id="">-->
                            </div>


                            <div class="mb-2">
                                <div class="radio-buttons-container">
                                    <div class="radio-button">
                                        <input (change)="handleDisplay($event)" name="radio-group" id="radio2"
                                               class="radio-button__input" type="radio" checked>
                                        <label for="radio2" class="radio-button__label">
                                            <span class="radio-button__custom"></span>
                                            Không thời hạn
                                        </label>
                                    </div>
                                    <div class="radio-button">
                                        <input (change)="handleDisplay($event)" name="radio-group" id="radio1"
                                               class="radio-button__input" type="radio">
                                        <label for="radio1" class="radio-button__label">
                                            <span class="radio-button__custom"></span>
                                            Có thời hạn
                                        </label>
                                    </div>
                                </div>

                            </div>

                            <div class="mb-2" *ngIf="isShowCalendar == true">
                                <p-calendar placeholder="01/01/2024" [(ngModel)]="date"></p-calendar>
                            </div>


                            <div class="d-flex justify-content-end">
                                <p-button (click)="addData(txtUsernameAdd, txtDescriptionAdd, date)" label="Lưu"
                                          icon="pi pi-check"></p-button>
                            </div>
                        </p-dialog>

                    </div>

                    <div class="box-table table-responsive">


                        <table class="table">
                            <thead class="table-dark">
                            <tr>
                                <td>ID</td>
                                <td>Tên chính sách</td>
                                <td>Action</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of listItem | paginate: { itemsPerPage: 5, currentPage: p }">
                                <td [innerHTML]="item.id"></td>
                                <td [innerHTML]="item.name"></td>
                                <td>

                                    <div class="row justify-content-center" style="gap: 10px">
                                        <div class="box">
                                            <p-button class="btn-view" icon="pi pi-eye" (click)="viewContent(item)"
                                                      label="Xem"></p-button>
                                            <p-dialog header="Nội dung" [(visible)]="isShowViewContent" [modal]="true"
                                                      [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw', height: '80vh' }"
                                                      [draggable]="false" [resizable]="false">
                                                <div class="view-edit-content" [innerHTML]="this.desContent">
                                                </div>
                                            </p-dialog>
                                        </div>

                                        <div class="box">
                                            <p-button (click)="editPolicy(item)" icon="pi pi-cog" label="Sửa"></p-button>

                                        </div>
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="pagination" (pageChange)="p = $event"></pagination-controls>

                    </div>

                </div>


            </section>
        </div>
    </div>
</div>

<p-dialog header="Sửa chính sách" [(visible)]="isShowEditPolicy" [modal]="true"
          [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw' }"
          [draggable]="false" [resizable]="false">

    <div class="mb-2">
        <label for="" class="form-label">Tên chính sách</label>
        <input type="text" [(ngModel)]="isDataPolicy.name" class="form-control" readonly>
    </div>

    <div class="mb-2">
        <label for="" class="form-label">Nội dung</label>
        <p-editor [(ngModel)]="isDataPolicy.content" [style]="{ height: '180px' }" data-value=""></p-editor>
    </div>

    <div class="mb-2">
        <div class="radio-buttons-container">
            <div class="radio-button">
                <input (change)="handleDisplayUpdate($event)" name="radio-group" id="radio3"
                       class="radio-button__input" type="radio" checked>
                <label for="radio3" class="radio-button__label">
                    <span class="radio-button__custom"></span>
                    Không thời hạn
                </label>
            </div>
            <div class="radio-button">
                <input (change)="handleDisplayUpdate($event)" name="radio-group" id="radio4"
                       class="radio-button__input" type="radio">
                <label for="radio4" class="radio-button__label">
                    <span class="radio-button__custom"></span>
                    Có thời hạn
                </label>
            </div>
        </div>

    </div>

    <div class="mb-2" *ngIf="isShowCalendarUpdate == true">
        <p-calendar placeholder="01/01/2024" [(ngModel)]="dateUpdate"></p-calendar>
    </div>

    <div class="d-flex justify-content-end">
        <p-button (click)="updateData(isDataPolicy.id, isDataPolicy.content)"
                  label="Lưu"
                  icon="pi pi-check"></p-button>
    </div>
</p-dialog>


