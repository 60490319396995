import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class TopBannerService {

    requestOptions: any;
    accesstoken


    constructor(private _http: HttpClient) {
        // this.requestOptions = {
        //     headers: new HttpHeaders({
        //         // 'Authorization': `Bearer ${this.accesstoken}`,
        //         'Content-Type': 'application/json'
        //     })
        // };
        this.accesstoken = localStorage.getItem('accesstoken');
    }

    getTopBanner() {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        let endpoint = `${environment.URL}/top_banner`;
        let topBannerResult = this._http
            .get(endpoint, requestOptions)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );


        return topBannerResult;
    }

    postData(accesstoken, myfile, description, url): Observable<any> {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            //'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        const formData = new FormData();
        formData.append('myfile', myfile);
        formData.append('description', description);
        let result = this._http.post<any>(`${environment.URL}${url}/`, formData, requestOptions);
        return result;
    }

    deleteData(accesstoken, id, url) {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        let result = this._http.delete(`${environment.URL}${url}/${id}`, requestOptions).pipe(map((res: any) => {
            return res;
        }));
        return result;
    }

    updateData(accesstoken, data, url) {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            //'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        const formData = new FormData();
        formData.append('description', data.description);
        formData.append('position', data.position);
        formData.append('status', data.tmpStatus);
        formData.append('myfile', data.tmpImage);

        ;

        let result = this._http.put(`${environment.URL}${url}/${data.id}`, formData, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }


}
