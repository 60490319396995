import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  requestOptions: any;

  constructor(private _http: HttpClient) {
    this.requestOptions = {
      headers: new HttpHeaders({
        // 'Authorization': `Bearer ${this.accesstoken}`,
        'Content-Type': 'application/json'
      })
    };
  }

  postData(username, password, url): Observable<any> {

    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    console.log('formData ', formData)

    return this._http.post<any>(`${environment.URL}${url}/`, formData);
  }


}
