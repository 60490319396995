<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <section id="drag-area">

                <div class='main-table-containter'>

                    <div class="d-flex justify-content-between mb-1 box-head">
                        <p-button (click)="showModalAdd()" icon="pi pi-plus" label="Thêm"></p-button>
                        <p-dialog header="Thêm phí vận chuyển" [(visible)]="isShowModalAdd" [modal]="true"
                                  [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw' }"
                                  [draggable]="false" [resizable]="false">
<!--                            <div class="mb-2 d-flex flex-column">-->
<!--                                <label for="" class="form-label">Code</label>-->
<!--                                <input type="text" pInputText [(ngModel)]="code" />-->
<!--                            </div>-->

                            <div class="mb-2 d-flex flex-column">
                                <label for="" class="form-label">Tên khu vực</label>
                                <input type="text" pInputText [(ngModel)]="name" />
                            </div>

                            <div class="mb-2 d-flex flex-column">
                                <label for="" class="form-label">Ghi chú</label>
                                <input type="text" pInputText [(ngModel)]="note" />
                            </div>

                            <div class="mb-2 d-flex flex-column">
                                <label for="" class="form-label">Giá</label>
                                <p-inputNumber inputId="integeronly" [(ngModel)]="price"> </p-inputNumber>
                            </div>
                            <div class="d-flex justify-content-end">
                                <p-button (click)="addData()" label="Lưu"
                                          icon="pi pi-check"></p-button>
                            </div>

                        </p-dialog>


                    </div>

                    <div class="box-table table-responsive">


                        <table class="table">
                            <thead class="table-dark">
                            <tr>
                                <td>ID</td>
                                <td>Code</td>
                                <td>Tên khu vực</td>
                                <td>Ghi chú</td>
                                <td>Giá</td>
                                <td>Action</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of listItem | paginate: { itemsPerPage: 5, currentPage: p }">
                                <td [innerHTML]="item.id"></td>
                                <td [innerHTML]="item.code"></td>
                                <td [innerHTML]="item.name"></td>
                                <td [innerHTML]="item.note"></td>
                                <td [innerHTML]="transform(item.price)"></td>

                                <td>

                                    <div class="row justify-content-center" style="gap: 10px">
<!--                                        <div class="box">-->
<!--                                            <p-button class="btn-view" icon="pi pi-eye" (click)="viewImage(item.url)"-->
<!--                                                      label="Xem"></p-button>-->
<!--                                            <p-dialog header="Hình ảnh" [(visible)]="isShowViewImage" [modal]="true"-->
<!--                                                      [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw' }"-->
<!--                                                      [draggable]="false" [resizable]="false">-->
<!--                                                <p class="text-center view-edit-img">-->
<!--                                                    <img src="{{urlImageEdit}}" alt="">-->
<!--                                                </p>-->
<!--                                            </p-dialog>-->
<!--                                        </div>-->

                                        <div class="box">
                                            <p-button (click)="editImage(item)" icon="pi pi-cog" label="Sửa"></p-button>

                                        </div>

                                        <div class="box">
                                            <p-button class="btn-remove" icon="pi pi-trash" (click)="removeImage(item)"
                                                      label="Xoá"></p-button>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="pagination" (pageChange)="p = $event"></pagination-controls>

                    </div>

                </div>

            </section>
        </div>
    </div>
</div>

<p-dialog header="Sửa banner" [(visible)]="isShowEdit" [modal]="true"
          [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw' }"
          [draggable]="false" [resizable]="false">

    <div class="mb-2 d-flex flex-column">
        <label for="" class="form-label">Tên khu vực</label>
        <input type="text" pInputText [(ngModel)]="isData.name" />
    </div>

    <div class="mb-2 d-flex flex-column">
        <label for="" class="form-label">Ghi chú</label>
        <input type="text" pInputText [(ngModel)]="isData.note" />
    </div>

    <div class="mb-2 d-flex flex-column">
        <label for="" class="form-label">Giá</label>
        <p-inputNumber inputId="integeronly" [(ngModel)]="isData.price"> </p-inputNumber>
    </div>



    <div class="d-flex justify-content-end">
        <p-button (click)="updateData(isData.id, isData.name, isData.note,isData.price)" label="Lưu"
                  icon="pi pi-check"></p-button>
    </div>
</p-dialog>


