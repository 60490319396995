import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class NewsService {

    accesstoken: string;

    constructor(private _http: HttpClient) {
        this.accesstoken = localStorage.getItem('accesstoken');
    }

    getNews() {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            // 'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        // ${environment.URL}
        
        let endpoint = `${environment.URL}/news/V2/template`

        let newsResult = this._http
            .get(endpoint, requestOptions)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );


        return newsResult;
    }


    postDataTemplate1(data): Observable<any> {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/news/template`

        let result = this._http.post<any>(endpoint, data, requestOptions);
        return result;
    }


    insertDataTemplate1V2(data): Observable<any> {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/news/V2/template`

        let result = this._http.post<any>(endpoint, data, requestOptions);
        return result;
    }

    updateDataDetails(data, body) {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/news/template/media/${data.id}`;


        let result = this._http.put(endpoint, body, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }

    updateContentNews(body, id) {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/news/V2/template/${id}`;


        let result = this._http.put(endpoint, body, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }

    updateData(body, id) {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/news/template/${id}`;

        console.log('endpoint ', endpoint)

        let result = this._http.put(endpoint, body, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }

    deleteData(id) {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/news/template/${id}`;


        let result = this._http.delete(endpoint, requestOptions).pipe(map((res: any) => {
            return res;
        }));
        return result;
    }

    deleteImage(id)
    {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/news/V2/template/image/${id}`;


        let result = this._http.delete(endpoint, requestOptions).pipe(map((res: any) => {
            return res;
        }));
        return result;
    }
    
    updateImage(formData,id)
    {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            // 'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/news/V2/template/image/${id}`;


        let result = this._http.put(endpoint, formData, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }
    updateThum(formData,id)
    {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            // 'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/news/V2/template/thum/${id}`;
        let result = this._http.put(endpoint, formData, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }

    insertImage(formdata, templateId): Observable<any> {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/news/V2/template/image`;

        let result = this._http.post<any>(endpoint, formdata, requestOptions);
        return result;
    }


}
