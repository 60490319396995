import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import Swal from 'sweetalert2';


@Injectable({
    providedIn: 'root'
})
export class IntroduceService {

    accesstoken: string;


    constructor(private _http: HttpClient) { 
        this.accesstoken = localStorage.getItem('accesstoken');

    }
    getIntroduce() {


        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            // 'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        // ${environment.URL}

        let endpoint = `${environment.URL}/introduce`

        let newsResult = this._http
            .get(endpoint, requestOptions)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );


        return newsResult;
    }



    updateIntroduce(data): Observable<any> {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',

        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let endpoint = `${environment.URL}/introduce`;

        let result = this._http.post<any>(endpoint, data, requestOptions);
        return result;
    }

}
