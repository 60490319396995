<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <section id="drag-area">

                <div class='main-table-containter'>

                    <div class="d-flex justify-content-between mb-1 box-head">
                        <p-button (click)="showModalAdd()" icon="pi pi-plus" label="Thêm"></p-button>
                        <p-dialog header="Thêm người dùng" [(visible)]="isShowModalAdd" [modal]="true"
                                  [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '30vw' }"
                                  [draggable]="false" [resizable]="false">
                            <div class="mb-2">
                                <label for="" class="form-label">Username</label>
                                <p>
<!--                                    <input style="width: 100%" [(ngModel)]="txtUsernameAdd" type="text" pInputText formControlName="text"/>-->
                                    <input [(ngModel)]="txtUsernameAdd" type="text" class="form-control" id="">
                                </p>
<!--                                <input  type="text" class="form-control" id="">-->
                            </div>

                            <div class="mb-2">
                                <label for="" class="form-label">Password</label>
                                <p class="input-pw">
                                    <p-password [(ngModel)]="txtPasswordAdd" [toggleMask]="true"></p-password>
                                </p>
<!--                                <input [(ngModel)]="txtDescriptionAdd" type="text" class="form-control" id="">-->
                            </div>
                            <div class="d-flex justify-content-end">
                                <p-button (click)="addData(txtUsernameAdd, txtPasswordAdd)" label="Lưu"
                                          icon="pi pi-check"></p-button>
                            </div>
                        </p-dialog>

<!--                        <div class="d-flex box-filter" style="gap: 10px">-->
<!--                            <div class="radio-input">-->
<!--                                <label>-->
<!--                                    <input (change)="filterStatus($event)" value="all" name="value-radio" id="value-1" type="radio" checked>-->
<!--                                    <span>Tất cả</span>-->
<!--                                </label>-->
<!--                                <label>-->
<!--                                    <input (change)="filterStatus($event)" value="1" name="value-radio" id="value-2" type="radio">-->
<!--                                    <span>Hoạt động</span>-->
<!--                                </label>-->
<!--                                <label>-->
<!--                                    <input (change)="filterStatus($event)" value="0" name="value-radio" id="value-3" type="radio">-->
<!--                                    <span>Không hoạt động</span>-->
<!--                                </label>-->
<!--                                <span class="selection"></span>-->
<!--                            </div>-->
<!--                            <span class="p-input-icon-right">-->
<!--                                <i class="pi pi-search"></i>-->
<!--                                <input type="text" pInputText placeholder="Nhập mô tả" (keydown)="search($event)"-->
<!--                                       [(ngModel)]="valueSearch"/>-->
<!--                            </span>-->
<!--                        </div>-->

                    </div>

<!--                    <div class="box-table table-responsive">-->


<!--                        <table class="table">-->
<!--                            <thead class="table-dark">-->
<!--                            <tr>-->
<!--                                <td>ID</td>-->
<!--                                <td>Mô tả</td>-->
<!--                                <td>Vị trí</td>-->
<!--                                <td>Trạng thái</td>-->
<!--                                <td>Ngày</td>-->
<!--                                <td>Action</td>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <tr *ngFor="let item of listItem | paginate: { itemsPerPage: 5, currentPage: p }">-->
<!--                                <td [innerHTML]="item.id"></td>-->
<!--                                <td [innerHTML]="item.description"></td>-->
<!--                                <td [innerHTML]="item.position"></td>-->
<!--                                <td>-->
<!--                                    <span *ngIf="item.status == 1; else elseBlock">Hoạt động</span>-->
<!--                                    <ng-template #elseBlock>-->
<!--                                        <span>Không hoạt động</span>-->
<!--                                    </ng-template>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                    <p>Ngày tạo: {{ item.created_at }}</p>-->
<!--                                    <p class="mb-0">Ngày cập nhật: {{ item.updated_at }}</p>-->
<!--                                </td>-->
<!--                                <td>-->

<!--                                    <div class="row justify-content-center" style="gap: 10px">-->
<!--                                        <div class="box">-->
<!--                                            <p-button class="btn-view" icon="pi pi-eye" (click)="viewImage(item.url)"-->
<!--                                                      label="Xem"></p-button>-->
<!--                                            <p-dialog header="Hình ảnh" [(visible)]="isShowViewImage" [modal]="true"-->
<!--                                                      [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '50vw' }"-->
<!--                                                      [draggable]="false" [resizable]="false">-->
<!--                                                <p class="text-center view-edit-img">-->
<!--                                                    <img src="http://18.224.109.137:1026/{{this.urlImageEdit}}" alt="">-->
<!--                                                </p>-->
<!--                                            </p-dialog>-->
<!--                                        </div>-->

<!--                                        <div class="box">-->
<!--                                            <p-button (click)="editImage(item)" icon="pi pi-cog" label="Sửa"></p-button>-->

<!--                                        </div>-->

<!--                                        <div class="box">-->
<!--                                            <p-button class="btn-remove" icon="pi pi-trash" (click)="removeImage(item)"-->
<!--                                                      label="Xoá"></p-button>-->
<!--                                        </div>-->
<!--                                    </div>-->

<!--                                </td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                        </table>-->
<!--                        <pagination-controls class="pagination" (pageChange)="p = $event"></pagination-controls>-->

<!--                    </div>-->

                </div>


            </section>
        </div>
    </div>
</div>


