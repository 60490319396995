import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {throwError} from "rxjs";
import Swal from "sweetalert2";



@Injectable({
    providedIn: 'root'
})
export class OrderService {
    accesstoken: string;


    constructor(private _http: HttpClient) {
        this.accesstoken = localStorage.getItem('accesstoken');

    }

    getOrder(status)
    {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            // 'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let subEndPont  = ``;
        if(status)
        {
            subEndPont = `?orderStatus=${status}`;

        }        
        let endpoint = `${environment.URL}/order${subEndPont}`

        let orders = this._http
            .get(endpoint, requestOptions)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );


        return orders;
    }

    updateData(status, id) {
        const headerDict = {
            Authorization: `Bearer ${this.accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let body = {
            "orderStatus": Number(status),
        }

        let endpoint = `${environment.URL}/order/${id}`;


        let result = this._http.put(endpoint, body, requestOptions).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'board page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));
        return result;
    }
}
