import {Component, OnInit} from '@angular/core';
import Swal from "sweetalert2";
import {PolicyService} from "../../_services/policy.service";


@Component({
    selector: 'app-policy',
    templateUrl: './policy.component.html',
    styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {

    constructor(private policyService: PolicyService) {

    }


    public breadcrumb: any;
    isShowModalAdd: boolean;
    txtUsernameAdd: string = '';
    txtDescriptionAdd: string = '';

    valuePolicy: any = 0;
    namePolicy: string = '';
    date: any = new Date('Mon Jan 01 2025 00:00:00 GMT+0700 (Giờ Đông Dương)');
    dateUpdate: any = new Date('Mon Jan 01 2025 00:00:00 GMT+0700 (Giờ Đông Dương)');
    isShowCalendar: boolean = false;

    isShowCalendarUpdate: boolean = false;

    isShowViewContent: boolean = false
    isShowEditPolicy: boolean = false;
    listItem = [];
    p: any;

    listTmp: any = [];
    filterArr: any = []

    desContent: any

    isDataPolicy: any = {}


    ngOnInit() {
        this.breadcrumb = {
            'mainlabel': 'Quản lý chính sách',
            'links': [
                {
                    'name': 'Home',
                    'isLink': true,
                    'link': '/changelog'
                },
                {
                    'name': 'Quản lý chính sách',
                    'isLink': false,
                    'link': '#'
                },
            ]
        };

        this.load();
    }

    removePolicy(item) {
        let accesstoken = localStorage.getItem('accesstoken');
        Swal.fire({
            title: 'Bạn có muốn xoá không ?',
            // text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                let id = item.id;

                this.policyService.deleteData(accesstoken, id, '/policies').subscribe(item => {

                });
                this.listItem = [];
                this.load();

                Swal.fire({
                    title: 'Đã xoá!',
                    // text: 'Your file has been deleted.',
                    icon: 'success'
                });
            }
        });
    }

    viewContent(item) {
        this.desContent = item.content
        this.isShowViewContent = true;
    }


    editPolicy(item) {
        this.isDataPolicy = item
        console.log('isDataPolicy ', this.isDataPolicy)
        this.isShowEditPolicy = true
    }

    handleDisplayUpdate(event: any) {
        this.isShowCalendarUpdate = !this.isShowCalendarUpdate
        this.dateUpdate = ''
    }

    updateData(id, content) {
        let accesstoken = localStorage.getItem('accesstoken');
        console.log('isDataPolicy ', this.isDataPolicy)
        console.log('this.dateUpdate ',this.dateUpdate)
        let tmpDateUpdate = this.dateUpdate
        let tmpNamePolicyUpdate = this.isDataPolicy.name
        let tmpValuePolicyUpdate = this.isDataPolicy.type

        let formattedDate
        if (this.dateUpdate) {
            let year = this.dateUpdate.getFullYear();
            let month = ("0" + (this.dateUpdate.getMonth() + 1)).slice(-2);
            let day = ("0" + this.dateUpdate.getDate()).slice(-2);

            formattedDate = `${year}-${month}-${day}`;

            console.log('formattedDate ', formattedDate)
        } else {
            formattedDate = ''
        }

        //
        let data = {id, content, formattedDate, tmpNamePolicyUpdate, tmpValuePolicyUpdate}
        //
        this.policyService.updateData(accesstoken, data, '/policies').subscribe(item => {
            Swal.fire({
                title: 'Sửa thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {

                this.listItem = [];
                this.load();
                this.isShowEditPolicy = false;

            });
        });


    }

    load() {
        let accesstoken = localStorage.getItem('accesstoken');

        this.policyService.getPolicy(accesstoken, '/policies').subscribe(item => {
            console.log(item);
            item.data.forEach(item => {
                item.created_at = this.convertToDdMmYyyy(item.created_at);
                item.updated_at = this.convertToDdMmYyyy(item.updated_at);
                this.listItem.push(item);
                this.listTmp = this.listItem;

            });
        });
    }

    convertToDdMmYyyy(timestamp) {
        const dateObject = new Date(timestamp);
        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = dateObject.getFullYear();
        return `${day}/${month}/${year}`;
    }

    showModalAdd() {
        this.isShowModalAdd = true;
    }

    handleChange(event: any) {
        this.valuePolicy = Number(event.target.value)
        // this.namePolicy = event.target.dataset.policy;
    }

    handleDisplay(event: any) {
        this.isShowCalendar = !this.isShowCalendar
        this.date = ''
    }

    addData(policy, content, date) {

        let accesstoken = localStorage.getItem('accesstoken');

        let tmpValuePolicy = this.valuePolicy
        let tmpNamePolicy = this.namePolicy

        let namePolicy

        if(tmpValuePolicy == 0){
            namePolicy = 'Chính sách bảo mật'
        }else if(tmpValuePolicy == 1){
            namePolicy = 'Chính sách bán hàng'
        }else if(tmpValuePolicy == 2){
            namePolicy = 'Chính sách giao hàng'
        }else if(tmpValuePolicy == 3){
            namePolicy = 'Chính sách kiểm hàng'
        }else if(tmpValuePolicy == 4){
            namePolicy = 'Chính sách thanh toán'
        }

        let formattedDate
        if (date) {
            let year = date.getFullYear();
            let month = ("0" + (date.getMonth() + 1)).slice(-2);
            let day = ("0" + date.getDate()).slice(-2);

            formattedDate = `${year}-${month}-${day}`;

            console.log('formattedDate ', formattedDate)
        } else {
            formattedDate = ''
        }


        let data = {namePolicy, tmpValuePolicy, tmpNamePolicy, content, formattedDate}


        this.policyService.postData(accesstoken, data, '/policies').subscribe(item => {

            Swal.fire({
                title: 'Thêm thành công',
                icon: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok'
            }).then((result) => {
                this.txtDescriptionAdd = '';
                this.listItem = [];
                this.load();
                this.isShowModalAdd = false;
            });

        });


    }


}
