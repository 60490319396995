<div class="app-content content">
        <div class="content-wrapper">
          <div class="content-header row mb-1">
          </div>
          <div class="content-body"><section class="flexbox-container">
      <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-md-4 col-10 box-shadow-2 p-0">
              <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                  <div class="card-header border-0 pb-0">
                      <div class="card-title text-center">
                          <h2>Đăng ký tài khoản</h2>
                      </div>
                  </div>
                  <div class="card-content">
<!--                        <app-social-signin></app-social-signin>-->
<!--                      <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1"><span>OR Using Email</span></p>-->
                      <div class="card-body">
                          <form class="form-horizontal" (ngSubmit)="tryRegister()">
                              <fieldset class="form-group position-relative has-icon-left">
                                  <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                                    placeholder="User Name">
                                  <div class="form-control-position">
                                      <i class="feather ft-user"></i>
                                  </div>
                                  <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstName.errors.required">Username is required</div>
                                    </div>
                              </fieldset>
<!--                              <fieldset class="form-group position-relative has-icon-left">-->
<!--                                  <input type="email" formControlName="email" class="form-control" email [ngClass]="{ 'is-invalid': submitted && f.email.errors }"-->
<!--                                  placeholder="Your Email Address" required>-->
<!--                                  <div class="form-control-position">-->
<!--                                      <i class="feather ft-mail"></i>-->
<!--                                  </div>-->
<!--                                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">-->
<!--                                        <div *ngIf="f.email.errors.required">Email is required</div>-->
<!--                                    </div>-->
<!--                              </fieldset>-->
                              <fieldset class="form-group position-relative has-icon-left">
                                  <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                   id="user-password" placeholder="Enter Password" required>
                                  <div class="form-control-position">
                                      <i class="la la-key"></i>
                                  </div>
                                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                                    </div>
                              </fieldset>
<!--                              <div class="form-group row">-->
<!--                                  <div class="col-md-6 col-12 text-center text-sm-left">-->
<!--                                    <div class="custom-control custom-checkbox mb-1">-->
<!--                                      <input type="checkbox" class="custom-control-input" name="remember-me" id="remember-me">-->
<!--                                      <label class="custom-control-label" for="remember-me">Remember Me</label>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                  <div class="col-md-6 col-12 float-sm-left text-center text-sm-right"><a href="recover-password.html" class="card-link">Forgot Password?</a></div>-->
<!--                              </div>-->
                              <button type="submit" class="btn btn-outline-info btn-block">
                                  <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                                <i class="feather ft-user" *ngIf="!submitted"></i> Register
                              </button>
                          </form>
                      </div>
                      <div class="card-body">
                          <a [routerLink]="['/login']" class="btn btn-outline-danger btn-block"><i class="feather ft-unlock"></i> Login</a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>

          </div>
        </div>
      </div>
