import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class PolicyService {

    requestOptions: any;

    constructor(private _http: HttpClient) {
        // this.requestOptions = {
        //     headers: new HttpHeaders({
        //         // 'Authorization': `Bearer ${this.accesstoken}`,
        //         'Content-Type': 'application/json'
        //     })
        // };
    }

    updateData(accesstoken, data, url): Observable<any> {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let body = {
            "name": data.tmpNamePolicyUpdate,
            "content": data.content,
            "validDate": data.formattedDate,
            "type": data.tmpValuePolicyUpdate
        }

        console.log('body ', body)


        let result = this._http.put(`${environment.URL}${url}/${data.id}`, body).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'support page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));

        return result;
    }

    deleteData(accesstoken, id, url) {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        let result = this._http.delete(`${environment.URL}${url}/${id}`, requestOptions).pipe(map((res: any) => {
            return res;
        }));
        return result;
    }

    getPolicy(accesstoken, url) {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };
        let policyResult = this._http
            .get(`${environment.URL}${url}/`, requestOptions)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );


        return policyResult;
    }

    // @ts-ignore
    postData(accesstoken, data, url): Observable<any> {
        const headerDict = {
            Authorization: `Bearer ${accesstoken}`,
            'Content-Type': 'application/json',
        };
        const requestOptions = {
            headers: new HttpHeaders(headerDict),
        };

        let body = {
            "name": data.namePolicy,
            "content": data.content,
            "validDate": data.formattedDate,
            "type": data.tmpValuePolicy
        }

        // const formData = new FormData();
        // formData.append('name', data.tmpNamePolicy);
        // formData.append('content', data.content);
        // formData.append('validDate', data.formattedDate);
        // formData.append('type', data.tmpValuePolicy);

        // let result = this._http.post<any>(`${environment.URL}${url}/`, body, requestOptions);

        let result = this._http.post(`${environment.URL}${url}`, body).pipe(map((res: any) => {
            return res;
        }), catchError(error => {
            return throwError(
                Swal.fire({
                    icon: 'error',
                    title: 'support page error !!!',
                    text: 'Try again ?',
                    footer: '<a href="">Contact us</a>'
                })
            );
        }));

        return result;
    }
}
