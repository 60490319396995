<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
            <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb>
        </div>
        <div class="content-body">
            <section id="drag-area">

                <div class='main-table-containter'>

                    <div class="row mb-1" style="gap: 10px">
                        <div class="box">
                            <p-button (click)="showModalAdd()" icon="pi pi-plus" label="Thêm"></p-button>

                        </div>


                    </div>

                    <div class="box-table table-responsive">


                        <table class="table">
                            <thead class="table-dark">
                            <tr>
                                <td>STT</td>
                                <td>Tiêu đề</td>
                                <td>Mô tả</td>
                                <td>Vị trí</td>
                                <!-- <td>Trạng thái</td> -->
                                <!-- <td>Ngày cập nhật</td> -->
                                <td>Hành động</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let item of listItem; index as i">
                                <td>{{ i + 1 }}</td>
                                <td [innerHTML]="item.title"></td>
                                <td class="txt-line" [innerHTML]="item.description"></td>
                                <td [innerHTML]="item.position"></td>
                                <!-- <td [innerHTML]="item.status"></td> -->
                                <!-- <td [innerHTML]="item.updated_at"></td> -->
                                <td>

                                    <div class="row justify-content-center" style="gap: 10px">
                                        <div class="box">

                                            <p-button class="btn-view" icon="pi pi-eye" (click)="viewImage(item)"
                                                      label="Xem"></p-button>


                                        </div>

                                        <div class="box">
                                            <p-button (click)="editImage(item)" icon="pi pi-cog"
                                                      label="Sửa"></p-button>

                                        </div>
                                        <div class="box">
                                            <p-button (click)="editImageleft(item)" icon="pi pi-cog"
                                                      label="Sửa ảnh"></p-button>

                                        </div>

                                        <div class="box">
                                            <p-button class="btn-remove" icon="pi pi-trash"
                                                      (click)="removeImage(item)" label="Xoá"></p-button>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>

                </div>

            </section>
        </div>
    </div>
</div>

<p-dialog header="Công thức" [(visible)]="isShowViewRecipes"
          [modal]="true" [breakpoints]="{ '750px': '90vw' }"
          [style]="{ width: '50vw', height: '80vh' }" [draggable]="false"
          [resizable]="false">

    <div class="grid-recipe">
        <div class="grid-item">
            <div class="mb-2">
                <swiper *ngIf="this.listImageMain && this.listImageMain.length > 0"
                        [loop]="true" [autoHeight]="true" [allowTouchMove]="true"
                        [autoplay]="{ delay: 300000, disableOnInteraction: false }"
                        [pagination]="{ clickable: true }"
                        [navigation]="true">
                    <ng-template swiperSlide
                                 *ngFor="let item of this.listImageMain">
                        <img style="height: 250px;    width: 100%;
    object-fit: cover;"
                             src="https://englishapi.tranchaumood.com/{{item}}" alt=""/>
                    </ng-template>
                </swiper>
            </div>
            <div class="">
                <h3 [innerHTML]="this.dataView.title"></h3>
                <div [innerHTML]="this.dataView.description"></div>
            </div>
        </div>


        <div class="grid-item text-center">
            <iframe
                    width="300"
                    height="450"
                    src="https://www.youtube.com/embed/Bpz7_fIQPgM?autoplay=1"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen>
            </iframe>
        </div>
    </div>

</p-dialog>

<p-dialog header="Sửa công thức" [(visible)]="isShowEditRecipes" [modal]="true" [breakpoints]="{ '750px': '90vw' }"
          [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
    <div class="mb-2">
        <label for="" class="form-label">Tiêu đề</label>
        <input type="text" [(ngModel)]="isDataRecipes.title" class="form-control" id="" value="{{isDataRecipes.title}}">
    </div>
    <div class="mb-2">
        <label for="" class="form-label">Đường dẫn video Youtube</label>
        <input [(ngModel)]="urlVideo" type="text" class="form-control" id="">
    </div>
    <div class="mb-2">
        <label for="" class="form-label">Mô tả</label>
        <p-editor [(ngModel)]="isDataRecipes.description" [style]="{ height: '180px' }" data-value=""></p-editor>
    </div>

    <div class="mb-2">
        <label for="txtarea3" class="form-label">Vị trí</label>
        <input type="number" class="form-control" id="txtarea3" [(ngModel)]="isDataRecipes.position"
               value="{{isDataRecipes.position}}">
    </div>

    <div class="mb-2">
        <p class="form-label">Trạng thái</p>

        <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" value="1" (change)="handleChange($event)"
                   [checked]="selectedOption == 1" type="radio" name="inlineRadioOptions" id="inlineRadio1">
            <label class="form-check-label cursor-pointer" for="inlineRadio1">Hoạt động</label>
        </div>
        <div class="form-check form-check-inline">
            <input class="form-check-input cursor-pointer" value="0" (change)="handleChange($event)"
                   [checked]="selectedOption == 0" type="radio" name="inlineRadioOptions" id="inlineRadio2">
            <label class="form-check-label cursor-pointer" for="inlineRadio2">Không hoạt động</label>
        </div>


    </div>


    <div class="d-flex justify-content-end">
        <p-button
                (click)="updateData(isDataRecipes.id, isDataRecipes.title, isDataRecipes.description, isDataRecipes.position)"
                label="Lưu" icon="pi pi-check"></p-button>
    </div>
</p-dialog>


<p-dialog header="Sửa ảnh {{imageLeftName}}" [(visible)]="isShowEditMediaDetails" [modal]="true"
          [breakpoints]="{ '750px': '90vw' }" [style]="{ width: '85vw', height: '80vh' }" [draggable]="false"
          [resizable]="false">

    <div class="flex justify-content-center mb-1">
        <p-button class="btn-save" icon="pi pi-check" (click)="addImageLeft(selectedItem)" label="Thêm ảnh"></p-button>

    </div>
    <div class="box-table table-responsive">


        <table class="table">
            <thead class="table-dark">
            <tr>
                <td>Hình ảnh</td>
                <td>Vị trí</td>
                <td>Trạng thái</td>
                <!-- <td>Loại</td> -->
                <td>Thay ảnh</td>
                <td>Action</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of this.listItemDetals;index as i">
                <td>
                        <span class="d-block table-img view-edit-img">
                            <img src="{{item.url}}" alt="" style="width: 100px; height: 100px; object-fit: cover;">
                        </span>
                </td>
                <td>
                    <input [(ngModel)]="item.position" type="number" min="1" class="form-control" id=""
                           value="{{item.position}}">
                </td>
                <td>
                    <div class="">

                        <div class="form-check form-check-inline">
                            <input class="form-check-input cursor-pointer" value="1"
                                   (change)="handleChangeDetails($event)" [checked]="item.status == 1" type="radio"
                                   name="inlineRadioOptions{{i+1}}" id="inlineRadio1">
                            <label class="form-check-label cursor-pointer" for="inlineRadio1">Hoạt động</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input cursor-pointer" value="0"
                                   (change)="handleChangeDetails($event)" [checked]="item.status == 0" type="radio"
                                   name="inlineRadioOptions{{i+1}}" id="inlineRadio2">
                            <label class="form-check-label cursor-pointer" for="inlineRadio2">Không hoạt
                                dộng</label>
                        </div>
                    </div>
                </td>
                <!-- <td [innerHTML]="item.type == 1 ? 'Ảnh chi tiết' : 'Ảnh Thum'"></td> -->

                <td>
                    <input #fileInputUpdateDetails accept="image/*"
                           (change)="uploadImageUpdateDetails($event, item)" type="file" id="">
                </td>
                <td>
                    <div class="row justify-content-center" style="gap: 10px">
                        <div class="box">
                            <p-button class="btn-save" icon="pi pi-check"
                                      (click)="saveDetails(item, fileInputUpdateDetails)" label="Lưu"></p-button>
                        </div>
                        <div class="box">
                            <p-button class="btn-remove" icon="pi pi-trash" (click)="removeImageLeft(item)"
                                      label="Xoá"></p-button>
                        </div>
                    </div>

                </td>
            </tr>
            </tbody>
        </table>

    </div>

</p-dialog>


<p-dialog header="Thêm mới ảnh" [(visible)]="isShowAddImage" [modal]="true" [breakpoints]="{ '750px': '90vw' }"
          [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false" (onHide)="resetFormAddImage()">
    <div class="mb-2">
        <!-- <input #addImageLeftInput accept="image/*" type="file" id="" 
            id=""> -->
        <div class="custom-file">
            <input #addImageLeftInput type="file" accept="image/*" class="custom-file-input"
                   formControlName="importFile" id="importFile" (change)="onFileChange($event.target.files)">
            <label class="custom-file-label" #labelImport for="inputGroupFile01">Chọn file</label>
            <div *ngIf="!labelImport" class="text-danger">Ảnh Thumnal là bắt buộc.</div>

        </div>
    </div>
    <div class="d-flex justify-content-end">
        <p-button (click)="saveImageLeft(selectedItem,addImageLeftInput)" label="Lưu" icon="pi pi-check"
                  [disabled]="!canSubmitAddImage()"></p-button>
    </div>
</p-dialog>

<p-dialog header="Đường dẫn video" [(visible)]="isShowVideo" [modal]="true" [breakpoints]="{ '750px': '90vw' }"
          [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false" (onHide)="resetAddRecipeForm()">
    <div class="mb-2">
        <input type="text" id="search-contacts" class="form-control" [(ngModel)]="urlVideo"
               placeholder="Nhập đường dẫn video">

    </div>
    <div class="d-flex justify-content-end">
        <p-button (click)="saveVideoUrl()" label="Lưu" icon="pi pi-check"></p-button>
    </div>
</p-dialog>

<p-dialog header="Thêm công thức" [(visible)]="isShowModalAdd" [modal]="true" [breakpoints]="{ '750px': '90vw' }"
          [style]="{ width: '50vw', height: '80vh' }" [draggable]="false" [resizable]="false"
          (onHide)="resetAddRecipeForm()">
    <div class="mb-2">
        <label for="" class="form-label">Tiêu đề *</label>
        <input [(ngModel)]="txtTitleAdd" type="text" class="form-control" id="">
        <div *ngIf="!txtTitleAdd" class="text-danger">Title là bắt buộc.</div>

    </div>
    <div class="mb-3">
        <p>Chọn ảnh Thumbnail *</p>
        <input #fileInputAdd accept="image/*" (change)="uploadImageThumbAdd($event)" type="file" id="formFile">
        <div *ngIf="!nameImageThumbAdd" class="text-danger">Ảnh Thumnal là bắt buộc.</div>

    </div>

    <div class="mb-3">
        <p>Chọn ảnh</p>
        <input #fileInputAdd1 accept="image/*" multiple (change)="uploadImageMainLeftAdd($event)" type="file"
               id="formFile1">
    </div>


    <div class="mb-2">
        <label for="" class="form-label">Đường dẫn video Youtube</label>
        <input [(ngModel)]="urlVideo" type="text" class="form-control" id="">
    </div>

    <div class="mb-2">
        <label for="" class="form-label">Mô tả</label>
        <p-editor [(ngModel)]="txtDescriptionAdd" [style]="{ height: '180px' }"></p-editor>
    </div>

    <div class="d-flex justify-content-end">
        <p-button (click)="addData(txtTitleAdd, txtDescriptionAdd, fileInputAdd, fileInputAdd1, fileInputAdd2)"
                  [disabled]="!canSubmitAdd()" label="Lưu" icon="pi pi-check"></p-button>
    </div>
</p-dialog>